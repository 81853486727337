import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import { Product } from "../../models";
import { DataStore } from "aws-amplify";

function ServicesMain() {
  //********Liste de groupe********////
  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    setProducts(await DataStore.query(Product));
  };
  useEffect(() => {
    fetchProducts();
    console.log("Products", products);
  }, []);
  
  return (
    <Fragment>
      <section className="pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <!-- FAQ START --> */}

              {/* <!-- FAQ END --> */}
              <h4 className="text-purple"> Pour les auteurs indépendants </h4>
              <p className="mb-5">
                <i className="">
                  Plus qu’une simple librairie en ligne, nous vous accompagnons
                  dans la matérialisation de votre projet.
                </i>
              </p>
              <div className="row g-4">
              {
                    products.map((service, id) => (
                      <div className="col-sm-3" key={id}>
                      <div class="card card_cc mt-4  bg-white shadow-sm p-2">
                        <div className="justify-content-center d-flex">
                          <img
                            src="images/servic/book-cover.png"
                            class="img-fluid servic_cover shadow-lg rounded "
                            alt="..."
                          />
                        </div>
                        <div class="card-body">
                          <h5 class="card-title card_title">
                          {service.title}
                          </h5>
                          <p class="card-text">
                          {service.description}
                          </p>
                        </div>
                        <div class="card-footer justify-content-center d-flex d-grid gap-2">
                          <Link
                            to={"/commande-de-service.html?IdService="+service.id}
                            class="btn btn-purple-soft btn-block "
                          >
                            Commander
                          </Link>
                        </div>
                      </div>
                    </div>
                     
                    ))}

              </div>

              {/* <div className="row g-4">
                <div className="col-sm-3">
                  <div class="card card_cc mt-4  bg-white shadow-lg p-2">
                    <div className="justify-content-center d-flex">
                      <img
                        src="images/servic/book-cover.png"
                        class="img-fluid servic_cover shadow-lg rounded "
                        alt="..."
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title card_title">
                        Création de couverturex
                      </h5>
                      <p class="card-text">
                        Confiez l’élaboration de la couverture de votre livre à
                        nos graphistes professionnels.
                      </p>
                    </div>
                    <div class="card-footer justify-content-center d-flex d-grid gap-2">
                      <Link
                        to={"/commande-de-service.html"}
                        class="btn btn-purple-soft btn-block "
                      >
                        Commander
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div class="card card_cm mt-4 bg-white shadow-sm p-2  mt-4">
                    <div className="justify-content-center d-flex">
                      <img
                        src="images/servic/analytics.png"
                        class="img-fluid servic_cover shadow-lg rounded "
                        alt="..."
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title card_title">
                        Correction de manuscrit
                      </h5>
                      <p class="card-text">
                        Nos professionnels de la langue française sont à votre
                        disposition. Faites corriger les fautes d’orthographe,
                        de grammaire, de syntaxe et de ponctuation de votre
                        manuscrit.
                      </p>
                    </div>
                    <div class="card-footer justify-content-center d-flex d-grid gap-2">
                      <Link
                        to={"/commande-de-service.html"}
                        class="btn btn-purple-soft btn-block "
                      >
                        Commander
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div class="card card_lrm shadow-lg p-2 bg-white mt-4">
                    <div className="justify-content-center d-flex">
                      <img
                        src="images/servic/books.png"
                        class="img-fluid servic_cover shadow-lg rounded "
                        alt="..."
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title card_title">
                        Lecture et Réécriture de manuscrit
                      </h5>
                      <p class="card-text">
                        Vous êtes en manque d’inspiration ? <br /> Vous
                        souhaitez améliorer le style et la qualité de votre
                        manuscrit ?<br />
                        Vous aimerez avoir une lecture approfondie de votre
                        texte ?<br /> Faites-vous aider par notre équipe de
                        professionnels pour bénéficier d’un regard extérieur.{" "}
                      </p>
                    </div>
                    <div class="card-footer justify-content-center d-flex d-grid gap-2">
                      <Link
                        to={"/commande-de-service.html"}
                        class="btn btn-purple-soft btn-block "
                      >
                        Commander
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div class="card card_cla shadow-sm p-2 bg-white mt-4">
                    <div className="justify-content-center d-flex">
                      <img
                        src="images/servic/audio-book.png"
                        class="img-fluid servic_cover shadow-lg rounded "
                        alt="..."
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title card_title">
                        Création de livre audio
                      </h5>
                      <p class="card-text">
                        Nous vous créons une version audio de votre livre pour
                        toucher un public en pleine croissance. Votre oeuvre
                        gagnera davantage de visibilité.
                      </p>
                    </div>
                    <div class="card-footer justify-content-center d-flex d-grid gap-2">
                      <Link
                        to={"/commande-de-service.html"}
                        class="btn btn-purple-soft btn-block "
                      >
                        Commander
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 pt-0 pt-lg-5 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h4 className="text-purple">
                Pour les établissements partenaires
              </h4>
              <p>
                <i>
                  Profitez d’un éventail de solutions permettant de faciliter
                  l’apprentissage et d’améliorer les performances de vos
                  étudiants.
                </i>
              </p>
              <p>
                <b>Solution clef en main</b>
              </p>
              <p>Ce que vous obtiendrez :</p>
              <ul>
                <li>
                  <b>Un compte d’administrateur</b>
                </li>
                <li>
                  <b>Des équipements à l’adhésion</b>
                </li>
                <li>
                  <b>Une assistante technique</b>
                </li>
              </ul>
              <p>
                Notre application web est intuitive, simple d’utilisation et
                adaptée à l’apprentissage en ligne.
              </p>
              {/* <p>
                Nous fournirons également l’équipement matériel nécessaire à
                l’adhésion et un support technique.
              </p> */}
              {/* <p>
                Si vous êtes un établissement et que vous souhaitez offrir un
                apprentissage exceptionnel à vos étudiants.
              </p> */}
              <p>
                <Link
                  to={"contactez-nous.html"}
                  className="btn btn-lg btn-purple-soft me-2 mb-4 mb-sm-0"
                >
                  contactez nous
                </Link>
              </p>
            </div>
            <div className="col-md-5">
              <img src="images/partner.svg" alt="" className="img-fluid " />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default ServicesMain;
