import React, { Fragment } from "react";
import FastAction from "../fastaction/FastAction";
import AuthorServices from "./AuthorServices";
import Carousel from "./Carousel";
import ElearningServices from "./ElearningServices";
import "./main.scss";
import ReadingServices from "./ReadingServices";
function Main(props) {
  return (
    <Fragment>
      <Carousel />
      <AuthorServices/>
      <ReadingServices/>
      <ElearningServices/>
      <FastAction/>
    </Fragment>
  );
}

export default Main;
