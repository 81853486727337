import React, { Fragment } from "react";
import HeaderPrivacy from "./HeaderPrivacy";

function MainPrivacy(props) {
  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                <p className="fs-5 fw-bold text-decoration-underline">Le but de cette politique de confidentialité </p>
                <p>
                  {" "}
                  La présente politique de confidentialité a pour objet
                  d'informer les utilisateurs sur la façon dont leurs données
                  personnelles sont recueillies depuis notre site internet,
                  notre application mobile, des traitements réservés à celles-ci
                  par Read-ing.Est désigné « utilisateur » tout auteur, lecteur,
                  établissement, fournisseur de services inscrit sur Read-ing.
                </p>{" "}
                <p>
                  {" "}
                  Cette politique de confidentialité fait partie intégrante des
                  conditions générales d’utilisation et conditions générales de
                  vente de notre site internet et application mobile.
                </p>
                <p className="fw-bold text-decoration-underline">1. Lois applicables Cette</p>
                <p>
                  politique est conforme aux lois énoncées dans la loi sur la
                  protection des renseignements personnels et les documents
                  électroniques (LPRPDE).
                </p>
                <p>
                  Pour les résidents des pays de l’UE, le Règlement général sur
                  la protection des données (RGDP régit toutes les politiques de
                  protection des données, quel que soit l’endroit où se trouve
                  le site. La présente politique de confidentialité vise à se
                  conformer au RGDP. S’il y a des incohérences entre la présente
                  politique et le RGDP, le RGDP s’appliquera.
                </p>
                <p>
                  Pour les résidents de l’État de Californie, cette politique de
                  confidentialité vise à se conformer à la California Consumer
                  Privacy Act (CCPA). S’il y a des incohérences entre ce
                  document et la CCPA, la législation de l’État s’appliquera. Si
                  nous constatons des incohérences, nous modifierons notre
                  politique pour nous conformer à la loi pertinente.
                </p>
                <p className="fw-bold">• Consentement </p>
                <p>
                  Les utilisateurs conviennent qu’en utilisant notre site, ils
                  consentent :
                </p>
                <p>
                  a. Aux conditions énoncées dans la présente politique de
                  confidentialité et
                </p>
                <p>
                  b.La collecte,l’utilisation et la conservation des données
                  énumérées dans la présente politique.
                </p>
                <p className="fw-bold text-decoration-underline">2. Données personnelles que nous collectons </p>
                <p className="fw-bold">• Données collectées automatiquement</p>
                <p>
                  Lorsque vous visitez et utilisez notre site et application
                  mobile, nous pouvons automatiquement recueillir et conserver
                  les renseignements suivants :
                </p>
                <p>a. Adresse IP </p>
                <p>b. Lieu </p>
                <p>c.Détails des matériels et logiciels de l’utilisateur </p>
                <p>
                  d. Lien sur lequel l’utilisateur clique tout en utilisant le
                  site
                </p>
                <p>e. Contenu que l’utilisateur consulte sur notre site</p>
                <p>
                  f. Services d'analyses (le nom de domaine, l’heure, la
                  date/l'empreinte de visite)
                </p>
                <p className="fw-bold">• Données recueillies de façon non automatique</p>
                <p>
                  Nous pouvons également collecter les données suivantes lorsque
                  l'utilisateur effectue des opérations sur notre site et
                  application :
                </p>
                <p>a. Prénom et nom </p>
                <p>b. Âge</p>
                <p>c. Date de naissance </p>
                <p>d. Sexe </p>
                <p>e. E-mail</p>
                <p>f. Numéro de téléphone</p>
                <p>g. Domicile </p>
                <p>h. Information de paiement </p>
                <p>i.Manuscrits</p>
                <p>j. Œuvres Numériques </p>
                <p>k. Cours </p>
                <p>
                  l. Données d’établissements (liste d’étudiants, d’enseignants
                  et de personnels etc.)
                </p>
                <p>m. Historique des commandes </p>
                <p>n. Données de remplissage automatique </p>
                <p>
                  Ces données peuvent être recueillies au moyen des méthodes
                  suivantes :
                </p>
                <p>
                  a. Création et connexion à un compte auteur, lecteur,
                  établissement et fournisseur de service.
                </p>
                <p>
                  Veuillez noter que nous ne collectons que les données qui nous
                  aident à atteindre l’objectif énoncé dans cette politique de
                  confidentialité. Nous ne recueillerons pas de données
                  supplémentaires sans vous en informer au préalable.
                </p>
                <p className="text-decoration-underline fw-bold">3. Commentnous utilisons les données personnelles </p>
                <p>
                  Les données personnelles recueillies sur notre site web et
                  application mobile seront utilisées uniquement aux fins
                  précisées dans la présente politique ou indiquées sur les
                  pages pertinentes de notre site. Nous n’utiliserons pas vos
                  données au-delà de ce que nous divulguerons.
                </p>
                <p>
                  Les données que nous recueillons automatiquement sont
                  utilisées aux fins suivantes :
                </p>
                <p>
                  a. Élaboration destatistiques commerciales, d'analyse et
                  d'outils marketing.
                </p>
                <p>
                  Les données que nous recueillons lorsque l’utilisateur exécute
                  certaines fonctions peuvent être utilisées aux fins suivantes
                  :
                </p>
                <p>
                  b. Création et connexion au compte auteur, lecteur,
                  établissement et fournisseur de service
                </p>
                <p>
                  c. Utilisation des services, tel que la publication des
                  oeuvres numériques par l'auteur, l'écoute ou la lecture de
                  celles-ci le client,
                </p>
                <p>
                  d. Gestion des comptes établissements et de fournisseur de
                  service.
                </p>
                <p>e. Mise en avant des auteurs et leurs oeuvres numériques,</p>
                <p>
                  f. Gestion de commande client tels que, achat de livre
                  numérique, facturation, enquêtes de satisfaction.
                </p>
                <p>
                  g. Traitement de commande, achat des services de correction et
                  réécriture de manuscrit, création de couverture ou de livre
                  audio.
                </p>
                <p>
                  h. Communication, gestion de la relation commerciale entre
                  l'utilisateur et Read-ing : Le suivi des ventes, la
                  facturation, la comptabilité, la gestion des impayés, la
                  fraude etc.
                </p>
                <p>
                  i. Améliorer la qualité de l’expérience utilisateur sur notre
                  site et application mobile.
                </p>
                <p className="text-decoration-underline fw-bold">4. Avec qui nous partageons les données personnelles</p>
                <p className="fw-bold">• Employé ou Prestataire de service</p>
                <p>
                  Nous pouvons divulguer à tout membre de notre organisation les
                  données utilisateur dont il a raisonnablement besoin pour
                  réaliser les objectifs énoncés dans la présente politique.
                </p>
                <p className="fw-bold">• Autres divulgations</p>
                <p>
                  Nous nous engageons à ne pas vendre ou partager vos données
                  avec des tiers, sauf dans les cas suivants :
                </p>
                <p>a. Si la loi l'exige</p>
                <p>b. Si elle est requise pour toute procédure judiciaire</p>
                <p>c. Pour prouver ou protéger nos droits légaux</p>
                <p>
                  d. À des acheteurs ou des acheteurs potentiels de cette
                  société dans le cas où nous cherchons à la vendre la société.
                </p>
                <p>
                  Si vous suivez des hyperliens de notre site vers un autre
                  site, veuillez noter que nous ne sommes pas responsables et
                  n’avons pas de contrôle sur leurs politiques et pratiques de
                  confidentialité.
                </p>
                <p className="text-decoration-underline fw-bold">5. Combien de temps nous stockons les données personnelles</p>
                <p >Nous ne conservons pas les données des utilisateurs au-delà de ce qui est nécessaire pour atteindre les fins pour lesquelles elles sont recueillies.</p>
                <p className="text-decoration-underline fw-bold">6. Comment nous protégeons vos données personnelles</p>
                <p>Afin d'assurer la protection de vos données, nous utilisons le protocole de sécurité aws amazon pour transmettre des renseignements personnels dans notre système.</p>
                <p>Toutes les données stockées dans notre système sont bien sécurisées et ne sont accessibles qu'à nos employés ou Prestataire de service. Nos employés ou prestataires sont liés par des accords de confidentialité stricts et une violation de cet accord entraînerait le licenciement.</p>
                <p>Bien que nous prenions toutes les précautions raisonnables pour nous assurer que nos données d’utilisateur sont sécurisées et que les utilisateurs sont protégés, nous ne demeurons pas à l'abri du risque. Internet en sa totalité peut être, parfois, peu sûr. Ainsi, nous sommes incapables de garantir la sécurité des données des utilisateurs au-delà de ce qui est raisonnablement pratique.</p>
                <p className="fw-bold">• Mineurs</p>
                <p>Pour les résidents de l’UE, le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux fins de la collecte de données. Les mineurs doivent avoir le consentement d’un représentant légal pour que leurs données soient recueillies, traitées et utilisées.</p>
                <p className="text-decoration-underline fw-bold">7. Vos droits en tant qu’utilisateur</p>
                <p>En tant qu’utilisateur, vous avez le droit d’accéder à toutes vos données personnelles que nous avons collectées. En outre, vous avez le droit de mettre à jour ou de corriger toute donnée personnelle en notre possession à condition qu’elle soit acceptable en vertu de la loi.</p>
                <p>Vous pouvez choisir de supprimer ou de modifier votre consentement à la collecte et à l’utilisation des données en tout temps, pourvu qu’il soit légalement acceptable de le faire et que vous nous en ayez informé dans un délai raisonnable.</p>
                <p className="fw-bold">• Comment modifier, supprimer ou contester les données recueillies</p>
                <p>Si vous souhaitez que vos renseignements soient supprimés ou modifiés d’une façon ou d’une autre, veuillez communiquer avec nous à l'adresse courriel suivant :</p>
                <p>Contact@be-reading.com </p>

                <p className="text-decoration-underline fw-bold">8. Politique sur les cookies</p>
                <p>Un cookie est un petit fichier, stocké sur le disque dur d’un utilisateur par le site Web et application mobile. Son but est de recueillir des données relatives aux habitudes de navigation de l’utilisateur.</p>
                <p>Nous utilisons les types de cookies suivants sur notre site :</p>
                <p className=" fw-bold">a. Cookies fonctionnels</p>
                <p>Nous les utilisons pour mémoriser toutes les sélections que vous faites sur notre site afin qu’elles soient sauvegardées pour vos prochaines visites.</p>
                <p className=" fw-bold">b. Cookies analytiques</p>
                <p>Cela nous permet d’améliorer la conception et la fonctionnalité de notre site en recueillant des données sur le contenu auquel vous accédez et sur lequel vous vous accrochez en utilisant notre site.</p>
                <p className=" fw-bold">c. Cookies de ciblage</p>
                <p>Ces cookies collectent des données sur la façon dont vous utilisez le site et vos préférences. Cela nous permet de rendre les informations que vous voyez sur notre site plus promotionnelles et ciblées pour vous.</p>
                <p>Vous pouvez choisir d’être averti chaque fois qu’un cookie est transmis. Vous pouvez également choisir de désactiver les cookies entièrement dans votre navigateur Internet, mais cela peut diminuer la qualité de votre expérience d’utilisation.</p>
                <p className="fw-bold">• Cookies tiers</p>
                <p>Nous pouvons utiliser des cookies tiers sur notre site pour atteindre les objectifs suivants :</p>
                <p>1. Surveiller les préférences des utilisateurs pour adapter les publicités autour de leurs intérêts.</p>
                <p className="text-decoration-underline fw-bold">9. Modifications</p>
                <p>Cette politique de confidentialité peut être modifiée à l’occasion afin de maintenir la conformité avec la loi et de tenir compte de tout changement à notre processus de collecte de données. Nous recommandons à nos utilisateurs de vérifier notre politique de temps à autre pour s’assurer qu’ils soient informés de toute mise à jour. Au besoin, nous pouvons informer les utilisateurs par courriel des changements apportés à cette politique.</p>
                <p className="text-decoration-underline fw-bold">10. Contact</p>
                <p>Si vous avez des questions à nous poser, n’hésitez pas à communiquer avec nous à l'adresse suivante :</p>
                <p>Contact@be-reading.com</p>
                <p>SARL Reading, Riviera Abatta 05 BP 30 97 Abidjan 05 Côte d'Ivoire.</p>
                <p>Date d’entrée en vigueur : 15 mai 2022</p>





              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default MainPrivacy;
