import React, { Fragment } from "react";

function AboutChapter3(props) {
  return (
    <Fragment>
      <div className="row g-4 mt-5 ">
        <div className="col-md-12">
          <p className="mb-3  text-purple text-center text-underline chap-title chap3">
            Chapitre 3
          </p>
          {/* <h3 className="text-center mb-4">Une Equipe</h3> */}
          <h3 className="text-center mb-5">Ceux en quoi nous croyons :</h3>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-sm-3">
              <div class="card card-com shadow-sm border-0">
                <div class="card-body">
                  <h5 class="card-title text-purple card-title-about">1</h5>
                  <h6 class="card-subtitle card-subtitle-about mb-2 text-muted">
                    Communauté
                  </h6>
                  <p class="card-text">
                    Nous voulons bâtir une véritable relation de confiance avec
                    vous, travailler ensemble pour répondre au besoin numérique
                    et valoriser nos talents.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div class="card card-inov shadow-sm border-0">
                <div class="card-body">
                  <h5 class="card-title text-purple card-title-about">2</h5>
                  <h6 class="card-subtitle card-subtitle-about mb-2 text-muted">
                    Innovation
                  </h6>
                  <p class="card-text">
                    Notre tendance à oser, à sortir de notre zone de confort,
                    nous pousse à rechercher une amélioration constante de nos
                    services pour une meilleure expérience utilisateur.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div class="card card-pro shadow-sm border-0">
                <div class="card-body">
                  <h5 class="card-title text-purple card-title-about">3</h5>
                  <h6 class="card-subtitle card-subtitle-about mb-2 text-muted">
                    Professionnalisme
                  </h6>
                  <p class="card-text">
                    Notre équipe a à coeur de comprendre votre besoin, pour
                    mieux vous accompagner et offrir un service de qualité.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div class="card card-inc shadow-sm border-0">
                <div class="card-body">
                  <h5 class="card-title card-title-about text-purple">4</h5>
                  <h6 class="card-subtitle card-subtitle-about mb-2 text-muted">
                    L’inclusion
                  </h6>
                  <p class="card-text">
                    Faisant partir de toutes nos actions, aucune ligne
                    éditoriale préétablie, nous valorisons tous les genres
                    littéraires car chaque oeuvre est unique.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AboutChapter3;
