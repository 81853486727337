import React from "react";
import { Fragment } from "react";

function MainCgu() {
  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
                <h5 className="text-center mb-5 text-decoration-underline">CONDITIONS GÉNÉRALES D'UTILISATION</h5>
                <p className="article">ARTICLE 1. Objet</p>
                <p>Le site Internet l’adresse  <a href="https://www.read-ing.com/">www.read-ing.com</a> (ci-après le « <strong>Site</strong> ») et les Services décrits dans les présentes Conditions Générales d’Utilisation sont édités, exploités et fournis par la Société READ – ING SARL (ci-après « <b> <b>Read-ing </b></b>»), sise Abidjan – Cocody, Riviera Abatta, 05 BP 3097 Abidjan 05, société au capital de 1 000 000 F CFA immatriculée au Registre du Commerce et du Crédit Mobilier d’Abidjan, No : CI-ABJ-03-2021-B13-08312.</p>
                <p>Les présentes conditions générales d'utilisation (dites « <b>CGU</b> ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par <b><b>Read-ing </b></b>et de définir les conditions d’accès et d’utilisation des services par « <b>l'Utilisateur</b> ».</p>
                 <p>Les présentes CGU sont accessibles sur le site à la rubrique « <b>CGU</b> ».</p>
                 <p>Le Site permet à un utilisateur de disposer d’un compte lui permettant d’accéder et de bénéficier des Services de Publication Numérique et des Services optionnels, le cas échéant en contrepartie du paiement du prix de ces Services.</p>
                 <p>Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’utilisateur. Chaque utilisateur accepte expressément les présentes CGU en cochant la case précédant le texte suivant : « <b>Je reconnais avoir lu et compris les CGU et je les accepte</b> ».</p>
                 <p>En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par le site.</p>
                 <p><b>Read-ing </b>se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.</p>
            </div>
          </div>
          </div>
          </section>
          <section>
            <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <p className="article">ARTICLE 2. Réclamation – Assistance technique</p>
                    <p>Pour toute information de nature technique ou relative au fonctionnement des Services, l’Auteur est invité à adresser sa demande soit par courrier électronique à l’adresse <b>contact@read-ing.com</b>, soit en remplissant le formulaire «<b>Contact</b>  », accessible, soit par courrier à l’adresse suivante : Abidjan – Cocody, Riviera Abatta, 05 BP 3097 Abidjan 05, contact@read-ing.com</p>
                </div>
            </div>
            </div>
          </section>
          <section>
            <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <p className="article">ARTICLE 3. Définitions</p>
                    <p>Pour les besoins des présentes C.G.U, les termes en majuscules ont la signification suivante :</p>
                    <ul>
                        <li>« <b>C.G.U.</b> » : désigne les présentes Conditions Générales d’Utilisation ;</li>
                        <li>« <b>Charte de Vie Privée</b> » : désigne la politique de confidentialité et de protection des données personnelles des Auteurs, mise en oeuvre par Read-ing, laquelle fait partie intégrante des présentes C.G.S. ;</li>
                        <li>« <b>Utilisateur </b>» : désigne l’ensemble des internautes, Auteurs, Éditeurs, Formateurs, visiteurs du Site, non-inscrits et ne disposants donc pas d’un compte ;</li>
                         <li>« <b>Librairie de <b>Read-ing </b></b>» : boutique en ligne accessible depuis l’application <b>Read-ing </b>et permettant d’acquérir des OEuvres Numériques en contrepartie du paiement d’un prix et de la souscription à des CGV ;</li>
                         <li>« <b>OEuvre Numérique </b>» : désigne un écrit ou audio diffusé en version électronique et accessible depuis la Librairie de <b>Read-ing </b>sous la forme d’un fichier informatique, téléchargeable, stockable uniquement dans l’application.</li>
                         <li><b>READ – ING SARL</b> (ci-après « <b>Read-ing </b>»), sise Abidjan – Cocody, Riviera Abatta, 05 BP 3097 Abidjan 05, société au capital de 1 000 000 F CFA immatriculée au Registre du Commerce et du Crédit Mobilier d’Abidjan, No : CI-ABJ-03-2021-B13-08312 ;</li>
                         <li>« <b>Services </b>» : désignent les services fournis par la Société sur le Site et sur l’application et décrits dans les présentes C.G.U. ;</li>
                         <li>« <b>Services spécifiques </b>» : désigne les Services fournis par la Société sur le Site, en particulier les Services de Publication Numérique et les Services Optionnels, en contrepartie du paiement d’un prix ;</li>
                         <li>« <b>Site</b> » : désigne le site Internet www.read-ing.com.</li>
                         <li>« <b>L’application</b> » : désigne l’application Read-ing</li>
                    </ul>
                </div>
            </div>
            </div>
          </section>
          <section>
            <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <p className="article">ARTICLE 4. Champ d’application – Acceptation – Modification des C.G.U.</p>
                    <p>Les présentes C.G.U. ont pour objet de définir les conditions dans lesquelles l’utilisateur bénéficie des Services fournis par la Société au travers du Site.</p>
                    <p>L'accès ou l'utilisation de tout ou partie des Services impliquent l'acceptation sans restriction ni réserve des présentes C.G.U. par l’utilisateur.</p>
                    <p>Les présentes C.G.U. pouvant faire l'objet de modifications, les conditions applicables sont celles en vigueur et accessibles sur le Site à la date d'accès au Site par l’utilisateur.</p>
                </div>
            </div>
            </div>
          </section>

          <section>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
              <p className="article">ARTICLE 5. Accès et disponibilité des services</p>
              <p>Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.</p>
              <p>L’Utilisateur non-membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire en remplissant le formulaire. En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse email.</p>
            <p>Pour accéder aux services, l’Utilisateur doit ensuite s'identifier à l'aide de son identifiant et de son mot de passe créer lors de son inscription. L’utilisateur est responsable de la préservation de la
confidentialité de son mot de passe, de ses informations à caractère personnel, ainsi que la mise à disposition de son/ses oeuvre(s), le cas échéant qui sont effectuées sur son compte.
Tout Utilisateur membre régulièrement inscrit pourra également solliciter sa désinscription en se rendant à la page dédiée sur son espace personnel. Celle-ci sera effective dans un délai raisonnable.
La Société fait ses meilleurs efforts afin de rendre ses Services disponibles 24 heures sur 24 et 7 jours sur 7, indépendamment des opérations de maintenance desdits Services.</p>
            <p>Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilité de Read-ing. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis.
L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email de l’éditeur communiqué à l’ARTICLE 1.</p>
            <p className="sous-article">5.1 DESCRIPTION DES SERVICES</p>
            <p>La Société met à la disposition des Internautes, au travers de son Site, l’accès à un certain nombre de Services visant à lui permettre notamment de :</p>
            <ul>
              <li><b className="text-decoration-underline">Publier avec <b>Read-ing </b></b>: Sur notre Application mobile de lecture en ligne, publiez et vendez vos OEuvres, Parlez du prix des services</li>
              <li><b className="text-decoration-underline">Service d’édition </b>: Les services seront facturés en totalité à la commande du service. Les services que nous offrons comprennent :
              <ul>
                <li>Création de couverture</li>
                <li>Correction de manuscrit</li>
                <li>Réécriture de manuscrit</li>
                <li>Création de livre audio</li>
              </ul>
              </li>
              <li><b className="text-decoration-underline">Service d’apprentissage en ligne</b> : Destine aux établissements partenaires :
              <ul>
                <li>Compte établissements (ils sont responsable de la gestion du compte, et soumis au respect du CGU)</li>
                <li>Assistance informatique</li>
                <li>Équipement Fournir : Demeure la propriété de Read-ing, confère un droit d’usage</li>
              </ul>
              </li>
            </ul>
            <p className="sous-article">5.2 ACCÈS AUX SERVICES</p>
            <p>Les Services sont exclusivement accessibles en ligne sur le Site.</p>
            <p className="sous-article">5.3 DISPONIBILITÉ DES SERVICES</p>
            <p>La Société fait ses meilleurs efforts afin de rendre ses Services disponibles 24 heures sur 24 et 7 jours sur 7, indépendamment des opérations de maintenance desdits Services et/ou Serveurs et/ou du Site ainsi que l’application.</p>
            <p>En tout état de cause, la Société se réserve la possibilité de modifier, interrompre, à tout moment, temporairement ou de manière permanente tout ou partie des Services et/ou de l’accès au Site ou l’application sans information préalable des Internautes.</p>
            <p className="text-decoration-underline">À ce titre, la Société est tenue d’une obligation de moyens.</p>
            <p>Plus particulièrement, la Société fait ses meilleurs efforts pour assurer la continuité des Services, cependant, compte tenu de la complexité et des circonstances spécifiques à l’activité particulière d’hébergeur, la Société ne peut être tenue qu’à une obligation de moyens au titre des présentes C.G.U.</p>
            <p>En conséquence la Société ne saurait être tenue responsable des difficultés ou impossibilités d’accès, de la lenteur de la connexion ou tout autre problème technique dus à des circonstances et/ou à des intermédiaires techniques extérieurs à la Société.</p>
            </div>
        </div>
    </div>
</section>
<section>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
              <p className="article">ARTICLE 6. Contrepartie – Financière</p>
              <p>Le Service de Publication Numérique est fourni à titre gracieux à l’Auteur et les Services Optionnels sont fournis à titre onéreux, le cas échéant sous forme d’Offres, selon la grille tarifaire disponible sur le site web sont exprimés en Francs CFA TTC et sont ceux en vigueur au jour de la commande.</p>
              <p>En contrepartie des services proposées par la société, l’utilisateur (l’auteur) percevra 60% du prix de vente Hors Taxe de chaque oeuvre. Par exemple, Monsieur KOUASSI publie sur le site Read-ing. Il définit le prix de son oeuvre « <b>le Célibataire </b>» a 10 000f CFA hors taxe. Ainsi, Reading percevra 40% du prix de vente de ladite oeuvre soit 4 000 f CFA et Monsieur KOUASSI 6 000f CFA sur chaque achat.</p>
              <p>Le prix de chaque Service et/ou Offre est susceptible d'évoluer.</p>
              <p>La redevance des auteurs s'effectue par virement (Bancaire/Mobile money) au plus tard le 5 de chaque mois à condition que l’auteur fasse un solde minimum de vingt mille (20 000) F CFA. Par exemple, Monsieur Kouadio a publié son oeuvre « <b>L’intrépide </b>». Il fixe le prix de vente à cinq mille (5 000) f CFA. Pour qu’il puisse percevoir ses redevances, il doit en vendre sept (07), soit 60% de trente-cinq mille (35 000) ce qui fait vingt et un mille (21 000) f CFA.</p>
              <p>Si le Manuscrit n’est pas conforme aux Règles de diffusion, <b>Read-ing </b>procédera au retrait automatique de la vente, avertira l’Auteur et lui versera les redevances déjà perçus sur la vente dudit manuscrit dans un délai de quinze (15) jours ouvrés.</p>
            </div>
        </div>
    </div>
</section>
<section>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
              <p className="article">ARTICLE 7. Garanties – Responsabilité</p>
              <p>En accédant au Site de la Société, l’utilisateur déclare, garantit et s'engage à :</p>
              <ul>
                <li>Accéder et utiliser le Site et/ou les Services en toute bonne foi, de manière raisonnable, non contraire aux termes des présentes C.G.U. et pour une utilisation strictement personnelle et à des fins non lucratives ;</li>
                <li>Ne pas utiliser de dispositifs ou logiciels autres que ceux fournis par la Société destinés à i) affecter ou tenter d'affecter le bon fonctionnement du Site et/ou des Services qu’elle contient ii) ou encore d'extraire, modifier, consulter, même en mémoire tampon ou temporaire, ou encore pour une utilisation individualisée, tout ou partie du Site ;</li>
                <li>Ne pas accéder et/ou utiliser, le Site et/ou les Services fournis par la Société à des fins illicites et/ou dans le but de causer un préjudice à la réputation et l'image de la Société ou plus généralement à porter atteinte aux droits, notamment de propriété intellectuelle, de la Société et/ou de tiers ;</li>
                <li>Ne pas commercialiser directement ou indirectement les Services et/ou l'accès aux Services et/ou l'accès au Site ;</li>
                <li>Ne pas réutiliser ni exploiter tout ou partie, du Site et des Services qu'il contient, en particulier à des fins commerciales et/ou collectives et/ou à des fins personnelles sous une forme et/ou un média non autorisé par la Société ;</li>
                <li>Ne pas reproduire, représenter tout ou partie du Site à des fins privées au-delà des exceptions légales prévues, en particulier par le Code de la propriété intellectuelle, ou en vue d'une commercialisation directe ou indirecte notamment auprès de tiers ;</li>
                <li>Ne pas limiter l'accès et l’utilisation, du Site et/ou des Services ;</li>
                <li>Ne modifier, y compris en mémoire tampon ou temporaire, aucune mention ou élément des Services et/ou du contenu du Site ;</li>
                <li>S’assurer que l’utilisation des Services, n’affecte ni ne compromette la stabilité, la sécurité et la qualité des Services, des réseaux, de la bande passante, ou des infrastructures de la Société, des autres Internautes ou des tiers ;</li>
                <li>Ne pas contrevenir aux dispositions des articles 323-1 à 323-7 du Code Pénal réprimant les pratiques dites de <b>hacking</b> » ;</li>
                <li>Ne pas utiliser et/ou exploiter les coordonnées électroniques et/ou postales des autres Internautes ou des auteurs/acheteurs (potentiels) d’OEuvres Numériques en vue de l'envoi en masse de courrier électronique de sollicitation et de se livrer à une pratique de « <b>spamming</b> » ;</li>
              </ul>
              <p>En cas de manquement à l'une ou l'autre de ces obligations, sans que cette liste ne soit limitative, l’utilisateur est informé que la Société aura la faculté de lui refuser ou à suspendre, unilatéralement et sans notification préalable, l'accès à tout ou partie des Services et/ou du Site.</p>
           <p>L’utilisateur accepte et reconnaît que la Société ne peut notamment garantir :</p>
            <ul>
              <li>Que les résultats de sa recherche de OEuvres Numériques lui donnent entière satisfaction ;</li>
              <li>Que les OEuvres Numériques sont conformes à ses attentes et à ses recherches ;</li>
            </ul>
            <p>L’utilisateur accepte sans réserve que si la responsabilité de la Société devait être engagée directement ou indirectement en sa qualité d'intermédiaire technique, par quelque personne ou entité que ce soit, la Société se réserve le droit de prendre, sans délai et de plein droit, toute mesure administrative et/ou technique, en ce compris à l’égard de l’utilisateur permettant de sauvegarder ses intérêts et/ou de se conformer aux obligations qui lui incombent.</p>

            </div>
        </div>
    </div>
</section>
   <section>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
               <p className="article">ARTICLE 8. Limitation de responsabilité</p>
               <p>L’utilisateur est seul responsable de l'utilisation qu'il fait du Site, ainsi que des Services auxquels il accède depuis le Site.</p>
               <p>La Société ne pourra en aucun cas être tenue responsable dans le cadre d'une procédure introduite à l'encontre de l’utilisateur qui se serait rendu coupable d'une utilisation non-conforme, du Site et/ou des Services qu'il procure.</p>
               <p>L’utilisateur reconnaît et accepte à cet égard qu'il fera son affaire personnelle de toute réclamation ou procédure formée contre la Société, du fait de l'utilisation non-conforme par lui des Services et/ou du Site.</p>
               <p>Le Site peut contenir des liens hypertextes renvoyant vers des sites Internet de tiers.</p>
               <p>À cet égard, compte tenu du caractère évanescent du contenu qui peut y être diffusé, la responsabilité de la Société ne saurait être engagée dans le cas où le contenu desdits site Internet de tiers contreviendrait aux dispositions légales et/ou réglementaires en vigueur.</p>
               <p>En toute hypothèse, la Société ne saurait être responsable :</p>
               <ul>
                <li>En cas d'indisponibilité des Services pour des raisons telles que la défaillance du réseau public d'électricité, la défaillance des réseaux câblés de télécommunications, la perte de connectivité au réseau Internet due aux opérateurs publics ou privés, notamment de l’utilisateur, dont les causes proviennent notamment de grèves, de tempêtes, de tremblements de terre ou de toute autre cause ayant les caractéristiques de la force majeure;</li>
                <li>En cas d'utilisation du Site et/ou des Services par tout internaute dans des conditions non-conformes aux termes des présentes C.G.U. ;</li>
                <li>Dans les limites de la législation en vigueur, pour tout dommage indirect et ce y compris notamment les pertes de profit, de données ou tout autre perte de biens incorporels, et ce même si la Société a été informée de la potentialité de tels dommages, pouvant survenir (i) de l'utilisation ou de l'impossibilité d'utiliser les Services (ii) suite à l'accès auxdits Services par un l’utilisateur non autorisé.</li>
               </ul>
               <p>La Société ne saurait être tenue responsable de tout dysfonctionnement de quelle que nature qu'il soit relatif au matériel informatique de l’utilisateur ainsi qu'à sa connexion d'accès à Internet, lors de l'accès au Site et plus généralement aux Services.</p>
               <p>Plus particulièrement, la Société ne saurait garantir l’utilisateur des Services sur :</p>
           <ul>
            <li>Les problèmes de vitesses d'accès au Site et/ou de vitesse de mise en mémoire tampon de l'ordinateur de l’utilisateur des vidéos accessibles via les Services que l’utilisateur serait susceptible de rencontrer ;</li>
            <li>La qualité des textes, informations, descriptifs, photographies et vidéogrammes adjoints ou contenus dans les OEuvres Numériques accessibles au moyen de ses Services ;</li>
           </ul>
           <p>La Société décline toute responsabilité quant au contenu des OEuvres Numériques.</p>
            </div>
        </div>
    </div>
</section>
<section>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
              <p className="article">ARTICLE 9. Contenu illicite</p>
              <p>En sa qualité de prestataire au sens des dispositions de la règlementation, notamment des OEuvres Numériques accessibles depuis le Site ou l’application, la Société ne peut matériellement pas organiser une surveillance générale des OEuvres Numériques qu’elle vend sur le Site ou l’application et ne peut en déterminer le caractère licite ou non.</p>
              <p>Au cas où l’utilisateur est amené à découvrir que tout ou partie d’une OEuvre Numérique – mis en ligne sur le Site ou l’application – pourrait revêtir un caractère manifestement illicite, l’utilisateur est invité à le signaler à la Société en adressant un courrier électronique dans les conditions de l’article 2.</p>
              <p>À cet égard, l’utilisateur s’engage à ne pas abuser de cette faculté, à défaut de quoi, il reconnaît et accepte qu’il s’expose à des poursuites pénales.</p>
            </div>
        </div>
    </div>
</section>

<section>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
              <p className="article">ARTICLE 10. Force majeure</p>
              <p>La responsabilité de la Société ne pourra pas être recherchée si l'exécution de l'une de ses obligations est empêchée ou retardée en raison d'un cas de force majeure tel que définie par la jurisprudence des Tribunaux français, et notamment les catastrophes naturelles, incendies, dysfonctionnement ou interruption du réseau de télécommunications ou du réseau électrique.</p>
            </div>
        </div>
    </div>
</section>
<section>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
              <p className="article">ARTICLE 11. Propriété intellectuelle</p>
              <p>La responsabilité de la Société ne pourra pas être recherchée si l'exécution de l'une de ses obligations est empêchée ou retardée en raison d'un cas de force majeure tel que définie par la jurisprudence des Tribunaux français, et notamment les catastrophes naturelles, incendies, dysfonctionnement ou interruption du réseau de télécommunications ou du réseau électrique.</p>
            <p className="sous-article">11.1 DROIT D'AUTEUR SUR LE SITE DE LA SOCIÉTÉ</p>
            <p>La Société est le titulaire ou le concessionnaire des droits de propriété intellectuelle tant de la structure générale du Site que de son contenu (textes, slogans, graphiques, images, vidéos, photos et autres contenus).</p>
            <p>Dès lors, conformément aux dispositions du Code de la propriété intellectuelle, toute représentation, reproduction, modification, dénaturation et/ou exploitation totale ou partielle du Site et/ou de leur contenu et/ou des Services, par quelque procédé que ce soit et sur quelque support que ce soit, sans l'autorisation expresse et préalable de la Société, est prohibée et constitue des actes de contrefaçon de droits d'auteur.</p>
            <p>De même, toute exploitation non autorisée du Site et/ou de son contenu et/ou des Services engage la responsabilité pénale et civile de l’utilisateur sur le fondement de la contrefaçon de droits d'auteur.</p>
            <p>La Société entend divulguer le Site et les Services qu'afin d'en permettre un accès sur le réseau Internet, et ce :</p>
           <ul>
            <li>Depuis un ordinateur ou un terminal équivalant disposant d'un accès à un ou plusieurs réseaux de télécommunications permettant l'accès au réseau Internet et d'un logiciel de navigation sur le réseau Internet (de type Internet Explorer, Mozilla Firefox, etc.) ;</li>
            <li>Un terminal mobile disposant d'un accès à un réseau de télécommunications permettant l'accès au réseau Internet (connexion Wifi, 3/4G, Edge, etc.) ;</li>
           </ul>
           <p>Toute autre utilisation du Site et/ou des Services est réputée de plein droit réservée à la Société et constituer une atteinte à son droit de divulgation sur le Site et/ou les Services.</p>
            <p className="sous-article">11.2 DROIT D’AUTEUR SUR LES OEUVRES</p>
            <p>Les auteurs gardent l’entièreté de la propriété intellectuelle de leurs oeuvres. Il s’agira ici de définir le cadre de collaboration idoine à l’utilisation des services de Read-ing. Ainsi, l’auteur concèdent à <b>Read-ing </b>:</p>
           <ul>
            <li>Le droit de numérisation, le droit de télécharger en tout ou partie, de façon temporaire sur l’application Read-ing</li>
            <li>Le droit de représentation et/ou de publication par tous procédés existants ou futurs et sur tout autre support commercial, à titre gratuit ou onéreux</li>
            <li>Le droit d’adaptation (incluant le droit de modification, perfectionnement, correction, arrangement, simplification, adjonction, recadrage, intégration de contenus tiers, transcription ou traduction dans une autre langue, création d’oeuvres dérivées, etc.) ; sous réserve des Services souscrits par l’Auteur et de leurs finalités.</li>
           </ul>
           <p>Ainsi, <b>Read-ing </b>peut, notamment, sous réserve des Services souscrits par l’Auteur :</p>
           <ul>
            <li>Distribuer et commercialiser l’oeuvre Numérique à travers l’application <b>Read-ing </b>et les Librairies Partenaires ;</li>
            <li>Copier et stocker le Manuscrit et l’oeuvre Numérique associés sur un ou plusieurs serveurs ;</li>
            <li>Communiquer les OEuvres Numériques, leurs métadonnées et leurs Caractéristiques aux Éditeurs Partenaires présents sur la Plateforme ;</li>
           <li>Convertir, reformater et encoder les Manuscrits en OEuvres Numériques, activer et optimiser l'affichage des OEuvres numériques sur le Service de lecture numérique, sous réserve de ne faire aucune modification de la substance des OEuvres numériques et étant précisé que <b>Read-ing </b>n'est pas responsable d'éventuelles inexactitudes ou erreurs survenues lors de la conversion ou du reformatage.</li>
           </ul>
           <p>L’Auteur autorise également, à titre gracieux, <b>Read-ing </b>à utiliser des extraits raisonnables, issus de son OEuvre Numérique, en tant que documentation promotionnelle, pour encourager les utilisateurs des Librairies Partenaires et/ou de l’application <b>Read-ing </b>à acheter l’oeuvre numérique. Ces extraits pourront représenter jusqu’à 5% de l’OEuvre Numérique.</p>
            <p>La présente licence est concédée pour la durée des présentes C.G.U. et pour le monde entier, compte tenu de leur utilisation sur le réseau Internet.</p>
            <p>La licence, ainsi accordée par l’Auteur à Read-ing, aux Librairies Partenaires et à leurs éventuels sous-traitants, n’entraîne aucun transfert d’aucun droit de propriété intellectuelle ou autre à leurs profits.</p>
            <p>Pour l’exécution des Services, <b>Read-ing </b>pourra être amené à mettre à disposition de l’Auteur des outils technologiques, sur lesquels <b>Read-ing </b>concède à l’Auteur une licence d’utilisation pour la durée des Services et pour le territoire du monde entier.</p>
            <p className="sous-article">11.3 SIGNES DISTINCTIFS</p>
            <p>Les marques, logos, dénominations sociales, sigles, noms commerciaux, enseignes et/ou nom de domaine de la Société et/ou de ses partenaires commerciaux mentionnés sur le Site, permettant l'accès aux Services mis à disposition par la Société, constituent des signes distinctifs insusceptibles d'utilisation sans l'autorisation expresse et préalable de leur titulaire.</p>
            <p>Toute représentation et/ou reproduction et/ou exploitation partielle ou totale de ces signes distinctifs est donc prohibée et constitutif de contrefaçon de marque, en application des dispositions du Livre 7 du Code de la propriété intellectuelle, d'usurpation de dénomination sociale, nom commercial et de nom de domaine engageant la responsabilité civile délictuelle de son auteur.</p>
            <p className="sous-article">11.4 BASES DE DONNÉES</p>
            <p>L’utilisateur reconnaît irrévocablement que le Site et les Services sont constitués d'une ou plusieurs bases de données mis à disposition par la Société en qualité de producteur desdites bases de données.</p>
            <p>Dès lors, l’utilisateur s’interdit de procéder à :</p>
            <ul>
              <li>L’extraction par transfert permanent ou temporaire de la totalité ou d'une partie qualitativement ou quantitativement substantielle du contenu d'une ou plusieurs des bases des données accessibles sur le Site et/ou les Services sur un autre support, par tout moyen et sous toute forme que ce soit, en ce compris à des fins d'utilisation ou de consultation par un média et/ou un procédé(s) non autorisé(s) par la Société ;</li>
              <li>La réutilisation, par la mise à la disposition du public de la totalité ou d'une partie qualitativement ou quantitativement substantielle du contenu d'une ou plusieurs des bases des données accessibles sur le Site et/ou les Services, quelle qu'en soit la forme, y compris par un lien hypertexte, un média et/ou un procédé(s) non autorisé(s) par la Société ;</li>
              <li>La constitution, l'édition, la maintenance, la mise à jour, l'importation, l'exportation, la mise à disposition de tiers, à titre gracieux ou onéreux, et la participation aux actes précités, d'une base de données concurrente issue de tout ou partie d'une ou plusieurs des bases de données de la Société ;</li>
              <li>La visualisation sur un écran par un autre procédé ou média que ceux par lesquels la Société entend divulguer le Site et les Services tels que précisés ;</li>
              <li>D’une manière générale, toute extraction, utilisation, stockage, reproduction, représentation ou conservation, directe ou indirecte, partielle ou totale, y compris en mémoire tampon ou temporaire, qualitativement ou quantitativement substantielle du contenu d'une ou plusieurs des bases de données de la Société, commise par l'un des procédés visés ci-dessus est strictement prohibée, y compris par un média non autorisé par la Société.</li>
            </ul>
            <p className="sous-article">11.5 CONTENU DES SERVICES</p>
            <p>L’utilisateur reconnaît et accepte que l’accès au Site et aux Services mis à sa disposition par la Société ne saurait emporter une quelconque cession ou concession des droits de propriété intellectuelle (droits d'auteurs notamment) et autres droits au bénéfice de l’utilisateur.</p>
            <p>L'accès aux Services est exclusivement limité à l'usage privé et personnel de l’utilisateur dans les conditions et limites définies dans les présentes C.G.U. et conformément aux dispositions de du Code de la propriété Intellectuelle.</p>
            <p>Ainsi, l’utilisateur reconnaît et accepte que l'usage privé et personnel qui lui est concédé par la Société, au titre de l'accès à ses Services, exclut notamment l'accès aux Services en vue d'une utilisation collective de son contenu, la reproduction, la représentation, la revente, l'échange, la location, le transfert à un tiers, la modification, l'adaptation, la correction, et ce tant à titre gracieux qu'onéreux, de tout ou partie du Site, des Services, et de leurs contenus.</p>
           <p className="sous-article">11.6 LIENS HYPERTEXTES</p>
           <p>Les liens hypertextes accessibles sur le Site, permettant l'accès aux Services, en direction d'autres sites Internet et d'une manière générale vers toutes ressources existantes sur Internet ne sauraient engager la responsabilité de la Société.</p>
           <p>L’utilisateur ne pourra en aucun cas mettre en place de liens hypertextes à destination de pages profondes des Sites Internet, permettant l'accès aux Services et ce, par quelconque procédé technique destiné à passer outre le champ d'identification de l’utilisateur ou à procéder à l'aspiration de tout ou partie du contenu des Services mis à disposition par la Société. La technique du « <b>framing</b> » est interdite, sauf autorisation expresse et préalable de la Société.</p>
            </div>
        </div>
    </div>
</section>
<section>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
              <p className="article">ARTICLE 12. Données à caractère personnel</p>
              <p>Les dispositions relatives à la protection des données personnelles des Internautes sont détaillées dans la Charte Vie Privée, laquelle fait partie intégrante des présentes C.G.U. et est accessible.</p>
            </div>
        </div>
    </div>
</section>
<section>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
              <p className="article">ARTICLE 13. Suspension – Résiliation</p>
              <p className="sous-article">13.1 – TORTS EXCLUSIFS DE L’AUTEUR</p>
              <p>En cas d’utilisation des Services par l’Auteur en contravention avec les termes des présentes C.G.U., l’Auteur reconnaît et accepte que <b>Read-ing </b>aura la faculté de suspendre l’hébergement de son Manuscrit / OEuvre Numérique sur l’application <b>Read-ing </b>et, le cas échéant, sur la Librairie Read-ing, et, plus généralement, de lui refuser, unilatéralement et sans notification préalable, l’accès à tout ou partie des Services, voire de résilier, de plein droit et aux torts exclusifs de l’Auteur, son Compte.</p>
              <p>Toute suspension, résiliation ou suppression de la diffusion d’OEuvre Numérique et/ou de l'accès au Site, aux torts exclusifs de l’Auteur, n’entraînera aucun dédommagement et/ou remboursement au bénéfice de ce dernier, sans préjudice de tous dommages et intérêts auxquels <b>Read-ing </b>pourrait prétendre.</p>
              <p>En cas de défaut de paiement, y compris par annulation ou répudiation de son paiement, <b>Read-ing </b>en avertira l’Auteur, l’enjoignant de régulariser la situation dans les plus brefs délais, étant précisé que les Services souscrits seront suspendus jusqu’au complet paiement du prix dans un délai raisonnable, à l’issue duquel <b>Read-ing </b>pourra résilier, de plein droit, le compte de l’Auteur, aux torts exclusifs de ce dernier.</p>
              <p>Conformément aux dispositions des lois en vigueur, et en sa qualité d’hébergeur des OEuvres Numériques diffusés sur la Librairie Read-ing, dans le cas où <b>Read-ing </b>devait être rendu destinataire d’une notification de tiers, lui faisant part du caractère manifestement illicite et/ou non-conforme d’une OEuvre Numérique, <b>Read-ing </b>se réserve le droit de suspendre – à titre conservatoire – la diffusion de l’OEuvre Numérique, tout comme l’accès par l’Auteur à son Compte, sans que l'Auteur ne puisse prétendre à aucune indemnisation du préjudice subi par l’Auteur lui-même ou tout tiers.</p>
              <p>À des fins de contrôle et de maintien de la sécurité et afin d'éviter l'altération de la sécurité des systèmes et des infrastructures, la Société pourra procéder à des opérations de surveillance ciblées et ponctuelles relatives à l'utilisation des Services et, le cas échéant, interrompre l'accès aux Services.</p>
              <p>La Société se réserve le droit d’informer les Librairies Partenaires tiers de toute suspension ou retrait d’une OEuvre Numérique et des motifs justifiant cette suspension et/ou ce retrait.</p>
            <p className="sous-article">13.2 – RESILIATION – SUSPENSION DU FAIT DE L’AUTEUR</p>
            <p>L’auteur est libre de résilier son compte ou de suspendre la diffusion d’une oeuvre numérique à tout moment. Si l’Auteur résilie ou suspend les Services et/ou les droits concédés à Read-ing, relatifs à une OEuvre Numérique spécifique, qui sont nécessaires pour sa diffusion et/ou sa distribution, ou si Read-ing a des raisons légitimes de croire qu’une OEuvre Numérique contrevient aux droits de tiers et qu’elle suspend les Services en conséquence, Read-ing se chargera immédiatement de retirer l’oeuvre numérique ou résilier le compte et transmettra, dans les meilleurs délais, une demande de retrait aux Librairies Partenaires, lesquelles pourront retirer l’ OEuvre Numérique de leurs propres supports dans un délai de dix (10) jours ouvrés. L’auteur recevra son solde de tout compte au plus tard le 5 du mois prochain conformément aux dispositions concernant la contrepartie financière. Les oeuvres numériques déjà achetées restent la propriété des lecteurs.</p>

            </div>
        </div>
    </div>
</section>
<section>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
              <p className="article">ARTICLE 14. Dispositions diverses</p>
              <p className="sous-article">14.1 CORRESPONDANCE – PREUVE</p>
              <p>Sauf disposition particulière dans les présentes C.G.U., les correspondances échangées entre la Société et l’utilisateur sont exclusivement assurées par courrier électronique. En application des articles 1316 et suivants du Code civil, l’utilisateur reconnaît et accepte que les informations délivrées par la Société par courrier électronique, sur le Site font foi entre lui et la Société. Les éléments tels que le moment de la réception ou de l'émission, ainsi que la qualité des données reçues feront foi par priorité telles que figurant sur le Site, ou telles qu'authentifiées par les procédures informatisées de la Société, sauf à en apporter la preuve écrite et contraire par l’utilisateur. La portée de la preuve des informations délivrées par le Site est celle accordée à un original au sens d'un document écrit papier, signé de manière manuscrite.</p>
              <p className="sous-article">14.2 INTÉGRALITÉ DES C.G.U.</p>
              <p>Les présentes C.G.U. expriment l'intégralité des obligations de la Société et de l’utilisateur. Le fait pour l'une des parties de ne pas se prévaloir d'un manquement par l'autre partie, à l'une quelconque des obligations visées dans les présentes, ne saurait être interprété pour l'avenir comme une renonciation à l'obligation en cause.</p>
              <p className="sous-article">14.3 NON VALIDITÉ PARTIELLE</p>
              <p>Dans l'hypothèse où une ou plusieurs stipulations des présentes C.G.U. seraient considérées comme nulles, réputées non écrites ou déclarées comme telles en application d'une loi, d'un règlement ou à la suite d'une décision d'une juridiction compétente ayant autorité de la chose jugée en dernier ressort, les autres stipulations garderont toute leur force et leur portée et resteront pleinement applicable, sauf si la ou les stipulations non valides présentaient un caractère substantiel et que leur disparition remettait en cause l'équilibre contractuel.</p>
              <p className="sous-article">14.4 TITRES</p>
              <p>En cas de difficultés d'interprétation entre l'un des titres figurant en tête des clauses des présentes C.G.U., et l'une des clauses, les titres seront déclarés inexistants.</p>
            </div>
        </div>
    </div>
</section>
<section>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
              <p className="article">ARTICLE 15. Droit applicable et règlement des litiges</p>
              <p>Les présentes CGU sont soumises au droit ivoirien.</p>
              <p>En cas de différend découlant de l’exécution ou de l’interprétation des dispositions des Conditions Générales de Vente, les parties s’engagent à se rapprocher et à tenter de trouver une solution amiable à leur différend.</p>
              <p>En cas d’échec, seuls les tribunaux ivoiriens seront compétents pour juger de ce litige.</p>
            </div>
        </div>
    </div>
</section>
    </Fragment>

  );
}

export default MainCgu;
