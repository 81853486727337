import React, { Fragment, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
function ReadingServices(props) {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  });
  return (
    <Fragment>
      <section className="reading-section py-5">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-7" data-aos="fade-up">
              <h3 className="title">
              Lisez et écoutez vos oeuvres préférées sur Read-ing app
              </h3>
              <p>
                Vous recherchez un livre ? Découvrez notre catalogue de <strong>livres
                pas chers</strong>  ainsi que de <strong>nouveaux auteurs</strong>. Que vous soyez roman,
                chronique, poésie, BD, presse et religieux pour votre plaisir;
                ou manuels éducatifs et pédagogiques pour votre formation, vous
                trouverez forcément votre bonheur sur notre <strong> application de
                lecture</strong>.
              </p>
              <p>
                Vous préférez <strong>les oeuvres audio </strong>? Read-ing app est votre
                <strong> librairie</strong> idéale! Parcourez notre bibliothèque de livres audio
                et de contes africains pour dénicher votre coup de coeur.
              </p>
      
              <div className=" mb-5">
                <a href="">
                  <img src="google-play.svg" alt="" className="logo-mobile" />
                </a>
                <a href="">
                  {" "}
                  <img src="app-store.svg" alt="" className="logo-mobile" />
                </a>
              </div>
            </div>

            <div className="col-md-5" data-aos="fade-down">
              <img
                src="reading-services.svg "
                className="img-fluid img-reading-border"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default ReadingServices;
