import React, { Fragment, useRef } from "react";
import ContactImg from "./ContactImg";
import emailjs from "@emailjs/browser";

function ContactMain() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zi51461",
        "template_ms2henl",
        form.current,
        "WDh2_BSkWaakER5ac"
      )
      .then(
        (result) => {
          console.log(result.text);
          setTimeout(function () {
            window.location.reload();
          }, 5000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <ContactImg />
              <div className="row">
                <div class="col-lg-12 mt-lg-0">
                  <div class="card card-body bg- shadow-sm0 py-1 h-100">
                    <h5 class="mb-3 text-purple">
                      <i class="fas fa-fw fa-map-marker-alt mt-1"></i> Adresse
                    </h5>
                    <ul class="list-inline mb-0">
                      <li class="list-item mb-3"> Abidjan, Côte d'Ivoire</li>
                      <h5 class="mb-3 text-purple">
                        <i class="fas fa-fw fa-envelope mt-1"></i> Email
                      </h5>
                      <li class="list-item mb-3"> contact@read-ing.com</li>
                      <h5 class="mb-3 text-purple">
                        <i class="fas a-fw  fa-business-time"></i> Horaire
                      </h5>
                      <li class="list-item mb-3">
                        Lundi–vendredi : 9h00 - 17h00
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <form
                ref={form}
                onSubmit={sendEmail}
                className="shadow-sm p-5 rounded "
              >
                <p>
                  Vous pouvez nous contacter de la manière qui vous convient
                </p>
                <p>
                  Nous sommes disponibles 24h / 24 et 7j / 7 par fax ou e-mail
                </p>
                <p>
                  Vous pouvez également utiliser un formulaire de contact rapide
                  ci-dessous ou visiter notre bureau personnellement.
                </p>

                {/* <!-- Name --> */}
                <div class="mb-4 bg-light-input">
                  <label for="yourName" class="form-label">
                    <b>Votre nom & prenom(s) *</b>
                  </label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    id="yourName"
                    required
                    name="to_name"
                  />
                </div>
                {/* <!-- Email --> */}
                <div class="mb-4 bg-light-input">
                  <label for="emailInput" class="form-label">
                    <b>Adresse email *</b>
                  </label>
                  <input
                    type="email"
                    class="form-control form-control-lg"
                    id="emailInput"
                    name="from_name"
                  />
                </div>
                {/* <!-- phone --> */}
                <div class="mb-4 bg-light-input">
                  <label for="emailInput" class="form-label font-weight-bold">
                    <b>Telephone *</b>
                  </label>
                  <input
                    type="tel"
                    class="form-control form-control-lg"
                    id="telInput"
                    name="from_phone"
                  />
                </div>
                {/* <!-- phone --> */}
                <div class="mb-4 bg-light-input">
                  <label for="emailInput" class="form-label font-weight-bold">
                    <b>Sujet *</b>
                  </label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    id="textInput"
                    required
                    name="from_subject"
                  />
                </div>
                {/* <!-- Message --> */}
                <div class="mb-4 bg-light-input">
                  <label for="textareaBox" class="form-label">
                    <b>Message *</b>
                  </label>
                  <textarea
                    required
                    class="form-control"
                    id="textareaBox"
                    rows="4"
                    name="message"
                  ></textarea>
                </div>
                {/* <!-- Button --> */}
                <div class="d-grid">
                  <button class="btn btn-lg btn-purple mb-0" type="submit">
                    Envoyer votre message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default ContactMain;
