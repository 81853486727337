import React, { Component, Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Hamburger.scss";
class Navbar extends Component {
  state = { clicked: false, collapseID: "" };
  handleClick = () => {
    document.getElementById("idNavbar").style.display = "block";
    this.setState({ clicked: !this.state.clicked });
  };
  toggleCollapse = () => {
    document.getElementById("idNavbar").style.display = "none";
    this.setState({ clicked: !this.state.clicked });
  };
  render() {
    return (
      <Fragment>
        <nav className="navbar navbar-expand-xl py-0 shadow-sm">
          <div className="container-fluid px-3 px-xl-5 py-0">
            <Link className="navbar-brand shadow-smx" to={"/"}>
              <img
                src="images/logo/logo-reading.svg"
                alt=""
                className="logotype "
              />
            </Link>
            <div className="menu-icon" onClick={this.handleClick}>
              <i
                className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
              ></i>
            </div>
            {/* <div className="hamburger-navbar shadow-sm"> */}
            <ul
              className={
                this.state.clicked
                  ? "nav-menu active nav flex-column"
                  : "nav-menu nav flex-column"
              }
              id="idNavbar"
            >
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"/"}
                  onClick={this.toggleCollapse}
                >
                  Accueil
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"/nos-services.html"}
                  onClick={this.toggleCollapse}
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"/nos-engagements.html"}
                  onClick={this.toggleCollapse}
                >
                  Engagements
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link "
                  to={"/Qui-sommes-nous.html"}
                  onClick={this.toggleCollapse}
                >
                  {" "}
                  Qui sommes nous mobile ?
                </Link>
              </li>
              <div className="d-flex">
                  <a
                    href="https://author.read-ing.com/" target={'_blank'}
                    className="nav-link menu-link btn btn-purple-soft text-account"
                  >
                    <i class="fas fa-user-lock"></i> Compte auteur
                  </a>
                </div>
            </ul>

            <div
              className="collapse navbar-collapse menu-navbar"
              id="navbarCollapse"
            >
              <div
                className="navbar-collapse w-100 collapse"
                id="navbarCollapse"
              >
                <ul className="navbar-nav navbar-nav-scroll me-auto bg-dangerx">
                  <li className="nav-item">
                    <Link
                      className="nav-link active menu-link"
                      aria-current="page"
                      to="/"
                    >
                      Accueil
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/nos-services.html"
                      className="nav-link menu-link"
                    >
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/nos-engagements.html"
                      className="nav-link menu-link"
                    >
                      Engagements
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/Qui-sommes-nous.html"
                      className="nav-link menu-link"
                      href="#"
                    >
                      Qui sommes nous?
                    </Link>
                  </li>
                  {/* bouton d'accès */}
                </ul>
                <div className="d-flex">
                  <a
                    href="https://author.read-ing.com/" target={'_blank'}
                    className="nav-link menu-link btn btn-purple text-account"
                  >
                    <i class="fas fa-user-lock"></i> Compte auteur
                  </a>
                </div>
                <i className="far fa-bars icono_menu"></i>
              </div>
            </div>
          </div>
        </nav>
      </Fragment>
    );
  }
}

export default Navbar;
