import React, { Fragment } from "react";
import HeaderPrivacy from "./HeaderPrivacy";
import MainPrivacy from "./MainPrivacy";

function Privacy(props) {
  return (
    <Fragment>
      {/* <HeaderPrivacy /> */}
      <MainPrivacy />
    </Fragment>
  );
}

export default Privacy;
