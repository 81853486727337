import React, { Fragment, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
function EngagementMain(props) {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  });
  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img
                src="images/reading-world2.svg"
                data-aos="zoom-in-up"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-7">
              <h1 className="mt-5 title">Large distribution </h1>
              <p className="">
                <i>Touchez un plus grand nombre de lecteurs</i>
              </p>
              <p>
              À travers notre application de lecture conçue spécialement pour répondre aux besoins des lecteurs,
touchez un plus grand nombre de lecteurs, peu importe votre genre littéraire. Nous assurons la <strong>vente de votre oeuvre numérique</strong> partout dans le monde.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="section02">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h1 className="mt-5 title">Transparence totale</h1>
              <p>
                <i>Vous êtes l’unique propriétaire de votre œuvre </i>
              </p>
              <p>
                En tant qu’auteur, <b>vos droits sont protégés et respectés</b>.
                Vous êtes libre de retirer votre ouvrage de notre plateforme à
                tout moment. Avec Reading, jouissez des fruits de votre art en
                toute transparence.
              </p>
            </div>
            <div className="col-md-5">
              <img
                src="images/search.svg"
                data-aos="zoom-in-up"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img
                src="images/workers.svg"
                data-aos="zoom-in-up"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-7">
              <h1 className="mt-5 title">Encadrement adapté</h1>
              <p className="">
                <i>Vous êtes votre propre éditeur</i>
              </p>
              <p>
                Nous vous offrons un encadrement professionnel de l'écriture de
                votre manuscrit à la publication. Nous prenons soin de votre
                <b> talent </b>
                en vous apportant le soutien d’une équipe accompagnatrice
                dynamique et professionnelle.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="section02 w-100">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h1 className="mt-5 title">Diversité des partenaires</h1>
              <p>
                <i>Un atout majeur que nous valorisons </i>
              </p>
              <p>
                Peu importe votre langue ou dialecte, il existe un public qui
                n’attend que vous. Read-ing est la plateforme idéale pour
                laisser les auteur(e)s et les conteur(e)s{" "}
                <b>s’exprimer librement</b> et dans la langue de leur choix.
                L’<strong>inclusivité</strong> fait partie de toutes nos actions, chaque auteur pourra rester fidèle à sa culture tout en la dévoilant à un public plus large et désireux de la connaître.
              </p>
            </div>
            <div className="col-md-5">
              <img
                src="images/partners.svg"
                data-aos="zoom-in-up"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img
                src="images/step1.svg"
                data-aos="zoom-in-up"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-7">
              <h1 className="mt-5 title">Accompagnement sur mesure</h1>
              <p className="">
                <i>Menez à bien vos projets</i>
              </p>
              <p>
                Nous nous engageons à fournir aux établissements une{" "}
                <b>plateforme interactive efficace</b> ainsi qu’un support
                matériel et technique pour mener à bien les différentes
                formations dispensées.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section>
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 position-relative overflow-hidden">
              <div class="bg-purple bg-opacity-10 rounded-3 p-3 h-100">
                <div class="position-absolute bottom-0 end-0 me-3">
                  <img
                    src="images/school3.svg"
                    class="h-100px h-sm-200px"
                    alt=""
                  />
                </div>
                <div class="row">
                  <div class="col-sm-8 position-relative">
                    <h3 class="mb-1 title">Etablissement</h3>
                    <p class="mb-3 h5 fw-light lead " >
                      Bénéficiez d'une plateforme interactive efficace...
                    </p>
                    <Link to="/contactez-nous.html" class="btn btn-purple mb-0">
                      Contactez nous
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 position-relative overflow-hidden">
              <div class="bg-secondary rounded-3 bg-opacity-10 p-3 h-100">
                <div class="position-absolute bottom-0 end-0 me-3">
                  <img
                    src="images/reading-world.svg"
                    class="h-100px h-sm-200px"
                    alt=""
                  />
                </div>
                <div class="row">
                  <div class="col-sm-8 position-relative">
                    <h3 class="mb-1">Je suis un auteur</h3>
                    <p class="mb-3 h5 fw-light lead small">
                      {" "}
                      Nous vous connectons aux lecteurs du monde.
                    </p>
                    <a href="#" class="btn btn-warning mb-0">
                      Créer un compte
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default EngagementMain;
