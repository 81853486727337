import React, { Fragment } from "react";

function OtherServices(props) {
  return (
    <Fragment>
      <section className="bg-other-service">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h3 className="text-center mb-5">Autres services de Read-Ing</h3>
                </div>
            </div>
            <div className="row">
          <div className="col-md-4">
            <div class="card shadow-sm">
                <div className="d-flex justify-content-center">
                        <img
                src="images/servic/book-cover.png"
                class="card-img-top img-other-service"
                alt="..."
              />  
                </div>
        
              <div class="card-body">
                <h5 class="card-title">Création de couverture</h5>
                <p class="card-text text-truncate-2">
                  Confiez l’élaboration de la couverture de votre livre à nos
                  graphistes professionnels.
                </p>
                <a href="#" class="btn btn-purple ">
                  Commander
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
          <div class="card shadow-sm">
                <div className="d-flex justify-content-center">
                        <img
                src="images/servic/book-cover.png"
                class="card-img-top img-other-service"
                alt="..."
              />  
                </div>
        
              <div class="card-body">
                <h5 class="card-title">Création de couverture</h5>
                <p class="card-text text-truncate-2">
                  Confiez l’élaboration de la couverture de votre livre à nos
                  graphistes professionnels.
                </p>
                <a href="#" class="btn btn-purple ">
                  Commander
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
          <div class="card shadow-sm">
                <div className="d-flex justify-content-center">
                        <img
                src="images/servic/book-cover.png"
                class="card-img-top img-other-service"
                alt="..."
              />  
                </div>
        
              <div class="card-body">
                <h5 class="card-title">Création de couverture</h5>
                <p class="card-text text-truncate-2">
                  Confiez l’élaboration de la couverture de votre livre à nos
                  graphistes professionnels.
                </p>
                <a href="#" class="btn btn-purple ">
                  Commander
                </a>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    </Fragment>
  );
}

export default OtherServices;
