import React, { Fragment } from 'react';

function PoliciesMain(props) {
    return (
        <Fragment>
          <section>
            <div className="container row col-md-12">
            Mentions légales
            </div>
          </section>
    <section class="section section-lg bg-default">
        <div class="container">
          <dl class="list-terms">
            <dt class="heading-6">Le but de cette politique de confidentialité </dt>
            <dd>
              La présente politique de confidentialité a pour objet d'informer les utilisateurs sur la façon dont leurs données personnelles sont recueillis depuis notre site internet, notre application mobile, des traitements réservés à celles-ci par Reading.
              <br/>
              Cette politique de confidentialité fait partie intégrante des conditions générales d’utilisation de notre site internet et application mobile.
            </dd>
            <dt class="heading-6">1. Lois Applicables</dt>
            <dd>
              <p>
                Cette politique est conforme aux lois énoncées dans la loi sur la protection des renseignements
                personnels et les documents électroniques (LPRPDE).
              </p>
              <p>
                Pour les résidents des pays de l’UE, le Règlement général sur la protection des données (RGDP régit
                toutes les politiques de protection des données, quel que soit l’endroit où se trouve le site. La présente
                politique de confidentialité vise à se conformer au RGDP. S’il y a des incohérences entre la présente
                politique et le RGDP, le RGDP s’appliquera.
              </p>
              <p>
                Pour les résidents de l’État de Californie, cette politique de confidentialité vise à se conformer à la California Consumer Privacy Act (CCPA). S’il y a des incohérences entre ce document et la CCPA, la législation  de  l’État  s’appliquera.  Si  nous  constatons  des  incohérences,  nous  modifierons  notre politique pour nous conformer à la loi pertinente.
              </p>

              <br/>
              <ul>
                <li>
                  <span class="custom-ol-title">* Consentement</span>
                  <p class="custom-ol-content">
                    Les utilisateurs conviennent qu’en utilisant notre site, ils consentent à :<br/><br/>
                    a. les conditions énoncées dans la présente politique de confidentialité et<br/>
                    b. la collecte, l’utilisation et la conservation des données énumérées dans la présente politique
                  </p>
                </li>
              </ul>
            </dd>
            <dt class="heading-6">2. Données personnelles que nous collectons</dt>
            <dd>
              Nous collectons uniquement les données qui nous aident à atteindre l'objectif défini dans la présente politique de confidentialité. nous ne collecterons pas
              toute donnée supplémentaire au-delà des données énumérées ci-dessous sans vous en informer au préalable.
              <br/>
              <span class="custom-ol-title">Données collectées automatiquement</span>
              <p>
                Lorsque vous visitez et utilisez notre site et application mobile, nous pouvons automatiquement recueillir et conserver les renseignements suivants :
                a. Adresse IP<br/>
                b. Lieu<br/>
                c. Détails matériels et logiciels<br/>
                d. Liens un utilisateur clique tout en utilisant le site<br/>
                e. Contenu que l’utilisateur consulte sur votre site<br/>
              </p>
              <span  class="custom-ol-title">Données recueillies de façon non automatique</span>
              <p>
                Nous pouvons également collecter les données suivantes lorsque l'utilisateur effectue des opérations sur notre site et application :
                a. Prénom et nom<br/>
                b. Age<br/>
                c. Date de naissance<br/>
                d. Sexe<br/>
                e. Email<br/>
                f. Numéro de téléphone<br/>
                g. Domicile<br/>
                h. Information de paiement<br/>
                i. Données de remplissage automatique<br/>
                j. Manuscrits<br/>
                k. Livres Numériques<br/>

              </p>
              <p>
                Ces données peuvent être recueillies au moyen des méthodes suivantes :
                <br/>
                <span class="custom-ol-title">Enregistrement d'un compte auteur ou client</span>
                <br/>
                Veuillez noter que nous ne collectons que les données qui nous aident à atteindre l’objectif énoncé dans
                cette politique de confidentialité. Nous ne recueillerons pas de données supplémentaires sans vous en
                informer au préalable.
              </p>
            </dd>
            <dt class="heading-6">3. Comment nous utilisons les données personnelles</dt>
            <dd>
              Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées dans la
              présente politique ou indiquées sur les pages pertinentes de notre site. Nous n’utiliserons pas vos
              données au-delà de ce que nous divulguerons.
              <br/>
              <br/>
              Les données que nous recueillons automatiquement sont utilisées aux fins suivantes :
              <br/>
              <p>
                a. Élaboration de  statistiques commerciales , d'analyse et d'outils marketing.
              </p>
              <p>
                Les données que nous recueillons lorsque l’utilisateur ( auteur / Client) exécute certaines fonctions
                peuvent être utilisées aux fins suivantes :
              </p>
              <ul>
                <li>a. Création et connexion au compte auteur et / ou client,</li>
                <li>b. Utilisation des services, tel que la publication des œuvres numériques par l'auteur, l'écoute ou la lecture de celles-ci client,</li>
                <li>c. Mise en avant des auteurs et leurs œuvres numériques,</li>
                <li>d. Gestion de commande client tels que, achat de livre numérique, facturation, enquêtes de satisfaction.</li>
                <li>e. Traitement de commande auteur, achat des services de correction et réécriture de manuscrit, création de couverture ou de livre audio.</li>
                <li>f. Communication, gestion de la relation commerciale entre l'auteur et Reading. ( le suivi des ventes, la facturation, la comptabilité, la gestion des impayés, la fraude etc)</li>
              </ul>
            </dd>
            <dt class="heading-6">4. Avec qui nous partageons les données personnelles</dt>
            <dd>
              <ul>
                <li>
                  <span class="custom-ol-title">* Employé ou Prestataire de service</span>
                  <span class="custom-ol-content">
                    Nous pouvons divulguer à tout membre de notre organisation les données utilisateur dont il a raisonnablement besoin pour réaliser les objectifs énoncés dans la présente politique.
                  </span>
                </li>
                <li>
                  <span class="custom-ol-title">* Autres divulgations</span>
                  <span class="custom-ol-content">
                    Nous nous engageons à ne pas vendre ou partager vos données avec des tiers, sauf dans les cas suivants :
                    <br/>
                    a. si la loi l'exige
                    <br/>
                    b. si elle est requise pour toute procédure judiciaire
                    <br/>
                    c. pour prouver ou protéger nos droits légaux
                    <br/>
                    d. à des acheteurs ou des acheteurs potentiels de cette société dans le cas où nous
                    cherchons à la vendre la société
                  </span>
                </li>
              </ul>
              <p>
                Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes pas
                responsables et n’avons pas de contrôle sur leurs politiques et pratiques de confidentialité.
              </p>
            </dd>
            <dt class="heading-6">5. Combien de temps nous stockons les données personnelles</dt>
            <dd>
              Nous ne conservons pas les données des utilisateurs au-delà de ce qui est nécessaire pour atteindre les
              fins pour lesquelles elles sont recueillies.
            </dd>
            <dt class="heading-6">6. Comment nous protégeons vos données personnelles</dt>
            <dd>
              Afin d'assurer la protection de vos données, nous utilisons le protocole de sécurité aws amazon pour
              transmettre des renseignements personnels dans notre système.
              <br/>
              Toutes les données stockées dans notre système sont bien sécurisées et ne sont accessibles qu'à nos
              employés ou Prestataire de service. Nos employés ou prestataires sont liés par des accords de
              confidentialité stricts et une violation de cet accord entraînerait le licenciement.
              <br/>
              <br/>
              Bien que nous prenons toutes les précautions raisonnables pour nous assurer que nos données
              d’utilisateur sont sécurisées et que les utilisateurs sont protégés, nous ne demeurons pas a l'abri du
              risque. Internet en sa totalité peut être, parfois, peu sûr. Ainsi, nous sommes incapables de garantir la
              sécurité des données des utilisateurs au-delà de ce qui est raisonnablement pratique.
              <ul>
                <li>
                  <span class="custom-ol-title">Mineurs</span>
                  <span class="custom-ol-content">
                    Pour les résidents de l’UE, le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux fins de la collecte de données. Les mineurs doivent avoir le consentement d’unreprésentant légal pour que leurs données soient recueillies, traitées et utilisées.
                  </span>
                </li>
              </ul>

            </dd>
            <dt class="heading-6">7. Vos droits en tant qu'utilisateur</dt>
            <dd>
              En tant qu’utilisateur, vous avez le droit d’accéder à toutes vos données personnelles que nous avons
              collectées. En outre, vous avez le droit de mettre à jour ou de corriger toute donnée personnelle en notre
              possession à condition qu’elle soit acceptable en vertu de la loi.
              <br/>
              Vous pouvez choisir de supprimer ou de modifier votre consentement à la collecte et à l’utilisation des
              données en tout temps, pourvu qu’il soit légalement acceptable de le faire et que vous nous en ayez
              informé dans un délai raisonnable.
              <ul>
                <li>
                  <span class="custom-ol-title">Comment modifier, supprimer ou contester les données recuillies</span>
                  <span class="custom-ol-content">
                    Si vous souhaitez que vos renseignements soient supprimés ou modifiés d’une façon ou d’une autre, veuillez communiquer avec nous a l'adresse courriel suivant :
                    <a href="mailto:contact@be-readin.com">
                      Contact@be-reading.com
                    </a>
                  </span>
                </li>
              </ul>
            </dd>
            <dt class="heading-6">8. Politique sur les cookies</dt>
            <dd>
              Un cookie est un petit fichier, stocké sur le disque dur d’un utilisateur par le site Web. Son but
              est de recueillir des données relatives aux habitudes de navigation de l’utilisateur.
              <br/>
              Nous utilisons les types de cookies suivants sur notre site :
              <ul>
                <li>
                  <span class="custom-ol-title">a. Cookies fonctionnels</span>
                  <span class="custom-ol-content">
                    Nous les utilisons pour mémoriser toutes les sélections que vous faites sur notre site afin qu’elles soient sauvegardées pour vos prochaines visites.
                  </span>
                </li>
                <li>
                  <span class="custom-ol-title">b. Cookies analytiques</span>
                  <span class="custom-ol-content">
                    Cela nous permet d’améliorer la conception et la fonctionnalité de notre site en recueillant des données sur le contenu auquel vous accédez et sur lequel vous vouS accrochez enutilisant notre site.
                  </span>
                </li>
                <li>
                  <span class="custom-ol-title">c. Cookies de ciblage</span>
                  <span class="custom-ol-content">
                    Ces cookies collectent des données sur la façon dont vous utilisez le site et vos préférences. Cela nous permet de rendre les informations que vous voyez sur notre site plus promotionnelles et ciblées pour vous
                  </span>
                </li>
                <li>
                  <span class="custom-ol-title">d. Cookies tiers</span>
                  <span class="custom-ol-content">
                    Nous pouvons utiliser des cookies tiers sur notre site pour atteindre les objectifs suivants :
                    <br/>
                    1.  Surveiller les préférences des utilisateurs pour adapter les publicités autour de leurs intérêts.
                  </span>
                </li>
              </ul>
              <p>
                Vous pouvez choisir d’être averti chaque fois qu’un cookie est transmis. Vous pouvez également
                choisir de désactiver les cookies entièrement dans votre navigateur Internet, mais cela peut diminuer la
                qualité de votre expérience d’utilisation.
              </p>
            </dd>
            <dt class="heading-6">9. Modifications</dt>
            <dd>
              Cette politique de confidentialité peut être modifiée à l’occasion afin de maintenir la conformité avec la
              loi et de tenir compte de tout changement à notre processus de collecte de données. Nous
              recommandons à nos utilisateurs de vérifier notre politique de temps à autre pour s’assurer qu’ils soient
              informés de toute mise à jour. Au besoin, nous pouvons informer les utilisateurs par courriel des
              changements apportés à cette politique.
            </dd>
            <dt class="heading-6">Contact</dt>
            <dd>
              Si vous avez des questions à nous poser, n’hésitez pas à communiquer avec nous a l'adresse suivante :
              <br/>
              <a href="mailto:contact@be-reading.com">
                <i class="fa fa-envelope mr-2"></i>Contact@be-reading.com
              </a>
              <br/>
              <a>
                <i class="fa fa-map-marker mr-2"></i> Rita avenue Ottawa, On K2G2H1
              </a>
              <br/>
              <br/>
              <p class="text-bold">
                Date d’entrée en vigueur : 31 octobre 2021
              </p>
            </dd>
          </dl>
          {/* </dl> */}

        </div>
      </section>
        </Fragment>
    );
}

export default PoliciesMain;