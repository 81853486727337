import React, { Fragment } from 'react';
import AboutHeader from './AboutHeader';
import AboutMain from './AboutMain';
import AboutTeams from './AboutTeams';
import './aboutus.scss'
function AboutUs(props) {
    return (
        <Fragment>
            <AboutHeader/>
            <AboutMain/>
            {/* <AboutTeams/>   */}
        </Fragment>
    );
}

export default AboutUs;