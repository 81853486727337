import React, { Fragment } from 'react';
import OrderCheckoutHeader from './OrderCheckoutHeader';
import OrderCheckoutMain from './OrderCheckoutMain';
import './order.scss'

function OrderCheckout(props) {
    return (
        <Fragment>
            {/* <OrderCheckoutHeader/> */}
            <OrderCheckoutMain/>
        </Fragment>
    );
}

export default OrderCheckout;