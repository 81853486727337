import React, { Fragment } from "react";
import PoliciesHeader from "./PoliciesHeader";
import PoliciesMain from "./PoliciesMain";

function Policies(props) {
  return (
    <Fragment>
      {/* <PoliciesHeader /> */}
      <PoliciesMain />
    </Fragment>
  );
}

export default Policies;
