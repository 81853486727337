import React, { Fragment } from 'react';
import Main from '../main/Main';

function Acceuil() {
    return (
        <Fragment>
             <Main />
        </Fragment>
    );
}

export default Acceuil;