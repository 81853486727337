import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
function OderPayement() {
  var data = JSON.stringify({
    apikey: "1773118837628ad28155f6b0.86985352", //   YOUR APIKEY
    site_id: "297297", //YOUR_SITE_ID
    mode: "PRODUCTION",
    transaction_id: Math.floor(Math.random() * 100000000).toString(), //
    amount: 100,
    currency: "XOF",
    alternative_currency: "",
    description: " TEST INTEGRATION ",
    customer_id: "172",
    customer_name: "KOUADIO",
    customer_surname: "Francisse",
    customer_email: "harrissylver@gmail.com",
    customer_phone_number: "+225004315545",
    customer_address: "Antananarivo",
    customer_city: "Antananarivo",
    customer_country: "CM",
    customer_state: "CM",
    customer_zip_code: "065100",
    notify_url: "https://webhook.site/d1dbbb89-52c7-49af-a689-b3c412df820d",
    return_url: "https://webhook.site/d1dbbb89-52c7-49af-a689-b3c412df820d",
    channels: "ALL",
    metadata: "user1",
    lang: "FR",
    invoice_data: {
      Donnee1: "",
      Donnee2: "",
      Donnee3: "",
    },
  });
  var config = {
    method: "post",
    url: "https://api-checkout.cinetpay.com/v2/payment",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  const handleClick = () => {
    axios(config)
      .then(function (response) {
        window.location.assign(response.data.data.payment_url);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <div className="card shadow-sm">
                <div className="card-body">
                  <div className="card-title">
                    <h3 className="text-left mb-5">
                      <i class="fas fa-money-check-alt text-purple"></i>{" "}
                      PAIEMENT
                    </h3>
                  </div>
                  <form class="row g-3 needs-validation" novalidate>
                    <div class="col-md-6 bg-light-input">
                      <label for="" class="form-label">
                        NOM
                      </label>
                      <input type="text" class="form-control" required />
                    </div>
                    <div class="col-md-6 bg-light-input">
                      <label for="" class="form-label">
                        PRENOMS
                      </label>
                      <input type="text" class="form-control" required />
                    </div>

                    <div class="col-md-6 bg-light-input">
                      <label for="" class="form-label">
                        TELEPHONE
                      </label>
                      <input type="text" class="form-control" required />
                    </div>
                    <div class="col-md-6 bg-light-input">
                      <label for="" class="form-label">
                        EMAIL
                      </label>
                      <input
                        type="email"
                        class="form-control bg-light-input"
                        required
                      />
                    </div>

                    <div class="col-12 bg-light-input">
                      <label for="formFile" class="form-label">
                        VOTRE FICHIER
                      </label>
                      <input class="form-control" type="file" required />
                    </div>
                    <div className="col-sm-12 bg-light-input mb-3">
                      <label for="" class="form-label">
                        MESSAGE
                      </label>
                      <textarea class="form-control" rows="3"></textarea>
                    </div>

                    <div class="col-12">
                      <button
                        class="btn btn-purple"
                        type="submit"
                        onClick={handleClick}
                      >
                        VALIDER LE PAIEMENT
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="card shadow-sm fixed-contentx border-0 p-0">
                <div className="cart-title card-title-border">
                  <h5 className="text-left text-purple bg-light p-2">
                    RESUME COMMANDE
                  </h5>
                </div>
                <div className="card-body">
                  <div className="detail-title">
                    <p className="text-centerX mb-4">
                      <i class="fas fa-check-circle text-orange"></i>{" "}
                      <b>
                        Correction de manuscrit &nbsp;&nbsp; 2501 - 5000 mots
                      </b>
                    </p>
                  </div>
                  <div className="detail-tarif d-flex justify-content-between mb-4">
                    <p className="text-price">Total TTC</p>
                    <p className="number-price">50 000 FCFA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default OderPayement;
