import React, { Fragment } from 'react';
import ContactHeader from './ContactHeader';
import ContactMain from './ContactMain';

function ContactUs(props) {
    return (
        <Fragment>
            <ContactHeader/>
            <ContactMain/>
        </Fragment>
    );
}

export default ContactUs;