import React, { Fragment } from "react";

function MainLeagal(props) {
  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
                {/* <p className="fs-5 fw-bold text-decoration-underline text-center">Mentions légales.</p> */}
                <p className="fw-bold fs-5">1. Éditeur de la Plateforme</p>
                <p>L’édition et la direction de la publication du site <a href=""> www.read-ing.com </a> est assurée par la SARLU Read-ing au capital de 1 000 000 FCFA, domicilié à Abidjan- Cocody-Abatta extension-Cité Oribat-Non loin de la pharmacie CEPHAS, immatriculé au Registre du Commerce et du Crédit Mobilier (RCCM) du Tribunal de commerce d'Abidjan sous le numéro CI-ABJ-03-2021-B13-08312.</p>
                <ul>
                    <li>Contact téléphonique : +225 0709829226</li>
                    <li>Directrice de la publication : Ama Désirée N’Guettia</li>
                    <li>Courriel : contact@be-reading.com</li>
                </ul>
                <p className="fw-bold fs-5">2. Hébergeur de la base de données</p>
                <p>Le site <a href=""> www.read-ing.com</a> est hébergé par la société Amazon web service Inc (AWS), dont le siège social est situé au 410 Terry avenue north, seattle WA 98109-5210, le numéro de téléphone : 1-206-266-1000.</p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default MainLeagal;
