import { Route, Switch } from "react-router-dom";
import AboutUs from "./components/about/AboutUs";
import Cgu from "./components/cgu/Cgu";
import ContactUs from "./components/contactus/ContactUs";
import Engagement from "./components/engagement/Engagement";
import Faq from "./components/faq/Faq";
import Acceuil from "./components/home/Acceuil";
import Leagal from "./components/legal/Leagal";
import Services from "./components/nosservices/Services";
import Checkout from "./components/order/Checkout";
import OderPayement from "./components/order/OderPayement";
import OrderCheckout from "./components/order/OrderCheckout";
import Policies from "./components/policies/Policies";
import Privacy from "./components/privacy/Privacy";
import Tarif from "./components/tarif/Tarif";
import NoMatch from "./NoMatch";
import Cgv from "./components/cgv/Cgv";
const router = () => {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Acceuil />
        </Route>
        <Route exact path="/Qui-sommes-nous.html">
          <AboutUs />
        </Route>
        <Route exact path="/nos-engagements.html">
          <Engagement />
        </Route>
        <Route exact path="/nos-services.html">
          <Services />
        </Route>
        <Route exact path="/commande-de-service.html">
          <OrderCheckout />
        </Route>
        <Route exact path="/payement-de-service.html">
        <OderPayement/>
        </Route>
        <Route exact path="/checkout-service.html">
        <Checkout/>
        </Route>
        <Route exact path="/contactez-nous.html">
          <ContactUs />
        </Route>
        <Route exact path="/faq.html">
          <Faq />
        </Route>
        <Route exact path="/nos-tarifs.html">
          <Tarif />
        </Route>
        <Route exact path="/conditions-de-utilisation.html">
          <Policies />
        </Route>
        <Route exact path="/politique-de-confientialite.html">
          <Privacy />
        </Route>
        <Route exact path="/conditions-générales-de-vente.html">
          <Cgv />
        </Route>
        <Route exact path="/conditions-generale-d-utilisation.html">
          <Cgu />
        </Route>
        <Route exact path="/mentions-legal.html">
          <Leagal />
        </Route>

        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </>
  );
};

export default router;
