import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function FaqHeader(props) {
  return (
    <Fragment>
      <section class="bg-purple bg-aboutus align-items-center d-flex">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <h1 class="text-purple">Nos FAQs</h1>
              <div class="d-flex justify-content-center">
                {/* <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-dark breadcrumb-dots mb-0">
                    <li class="breadcrumb-item">
                      <Link to="/" className="text-purple">Accueil</Link>
                    </li>
                    <li class="breadcrumb-item active text-purple" aria-current="page">
                     FAQ
                    </li>
                  </ol>
                </nav> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default FaqHeader;
