import React from 'react';

function HeaderLeagal(props) {
    return (
        <section className="bg-purple bg-aboutus align-items-center d-flex">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <h1 class="text-purple text-shadow">Mentions légales</h1>
              <div class="d-flex justify-content-center">
                {/* <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-dark breadcrumb-dots mb-0">
                    <li class="breadcrumb-item">
                      <Link to="/">Accueil</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Conditions d'utilisation
                    </li>
                  </ol>
                </nav> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default HeaderLeagal;