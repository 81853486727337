import React from "react";
import { Fragment } from "react";
import MainCgu from "./MainCgu";
import  './cgu.css';

function Cgu() {
  return (
    <Fragment>
      <MainCgu />
    </Fragment>
  );
}

export default Cgu;
