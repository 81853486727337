import React, { Fragment } from "react";
import ReadMore from "../../ReadMore";
import AboutChapter2 from "./AboutChapter2";
import AboutChapter3 from "./AboutChapter3";

function AboutMain(props) {
  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row g-4">
            <div className="col-md-12">
              <h3 className="text-center  mb-4">Une vision</h3>
              <p className="text-center mb-5">
                Offrir à notre communauté le meilleur <br /> du monde numérique.
              </p>
            </div>
          </div>
          {/*  */}
          <div className="row g-4">
            <div className="col-md-12">
              <p className="mb-3 text-purple text-center text-underline chap-title chap1">
                  Chapitre 1
              </p>
              <h3 className="text-center mb-5">Une histoire</h3>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-4">
              <img src="images/about/aboutus.svg" alt="" />
            </div>
            <div className="col">
              <h5 className=" mb-4 text-orange">
                Tout commence avec un rêve d’enfant
              </h5>
              <p>
                Désireuse d’écrire ma propre histoire depuis ma tendre enfance,
                ce rêve a pu se réaliser en 2020 lors de la{" "}
                <strong>publication</strong> de ma première oeuvre littéraire.
                Cependant, le parcours a été éprouvant et laborieux, car m’ayant
                confronté à divers défis tels que le{" "}
                <strong>manque d’informations</strong> , de{" "}
                <strong>supports</strong> et de <strong>soutiens </strong>
                lorsqu’on décide de se lancer seul. De cette expérience est née
                une vision, celle de permettre à des écrivains noirs de publier
                leurs livres facilement et d’obtenir la visibilité qu’ils
                méritent. C’est ainsi qu’est né le projet :{" "}
                <strong>Read-ing !</strong>
              </p>
              <ReadMore>
                Pour accompagner ce projet, j’ai également voulu trouver une
                solution pour une autre problématique qui m’a en premier été
                présentée par mes petites soeurs étudiantes en Afrique. Elles
                ont attiré mon attention sur leurs difficultés à suivre
                les cours à distance durant la pandémie
                COVID-19. Ce tout nouveau système
                d’apprentissage a été une épreuve pour de
                nombreux
               établissements. Parce que lire c’est
                apprendre, nous avons donc décidé d’intégrer à notre
               section destinée aux manuels éducatifs,
                l’option d’assister à
               des cours en visioconférence. Pour y accéder,
                une simple application mobile de lecture et
                d’écoute numérique suffit. Ainsi,Read-ing dans
                sa vision nouvelle est une plateforme d’auto-édition, de lecture
                et d’apprentissage en ligne conçue pour répondre aux besoins
                numériques de notre communauté.
                </ReadMore>
              <p className="float-end">
                <strong>Ama Desiree, fondatrice de Read-ing </strong>
              </p>
            </div>
          </div>

          {/*  */}
          <AboutChapter2 />
          {/*  */}
          <AboutChapter3 />
        </div>
      </section>
    </Fragment>
  );
}

export default AboutMain;
