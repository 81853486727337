import React, { Component, Fragment, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default class Carousel extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      lazyLoad: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      swipeToSlide: true,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Fragment>
        <section className="bg-purplxe carousel-section p-0">
          <div className="row">
            <div className="col-sm-12">
              <div>
                <Slider {...settings}>
                  <div>
                    {/* <h1 className="carousel-caption"> </h1> */}

                    <h1 className="display-2 from-start mb-lg-4 slider-text">
                      Read-ing, votre plateforme d’auto-édition, de lecture et
                      d’apprentissage numérique.
                    </h1>

                    <img
                      src="images/slider/Slider-1.png"
                      class="img-fluid img-sm"
                      alt="..."
                    />
                  </div>
                  <div>
                    <h1 className="display-2 from-start mb-lg-4 slider-text">
                      Plus qu’une simple librairie en ligne, nous vous
                      accompagnons dans la matérialisation de votre projet,
                      chers auteurs.
                    </h1>
                    <img
                      src="images/slider/Slider-2.png"
                      class="img-fluid img-sm"
                      alt="..."
                    />
                  </div>
                  <div>
                    <h1 className="display-2 from-start mb-lg-4 slider-text">
                      Avec Read-ing, l’E-learning  n’aura  jamais été aussi  simple
                    et efficace.
                    </h1>
                    <img
                      src="images/slider/Slider-3.png"
                      class="img-fluid img-sm"
                      alt="..."
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
