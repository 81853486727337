import React, { Fragment } from 'react';
import TarifHeader from './TarifHeader';
import TarifMain from './TarifMain';

function Tarif(props) {
    return (
        <Fragment>
            <TarifHeader/>
            <TarifMain/>
        </Fragment>
    );
}

export default Tarif;