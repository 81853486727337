import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import OtherServices from "../nosservices/OtherServices";
import { Product } from "../../models";
import { DataStore } from "aws-amplify";

function OrderCheckoutMain(props) {
  //const { IdService } = useParams();
  const IdService = new URLSearchParams(window.location.search);
  const idService=IdService.get('IdService')
  // let IdService='3c5343a8-31c8-4dce-ab6f-ed08ac026f5e';
  // alert(IdService)
    //********Liste de groupe********////
    const [products, setProducts] = useState([]);
    const fetchProducts = async () => {
      setProducts(await DataStore.query(Product,idService));
    };
    // useEffect(() => {
    //   fetchProducts();
    //   console.log("Products", products);
    // }, []);

    useEffect(() => {
      if (idService) {
        fetchProducts();
        const ProductSubscribe = DataStore.observe(Product).subscribe(() =>
        fetchProducts()
        );
        return () => {
          ProductSubscribe.unsubscribe();
        };
      }
    }, [idService]);



  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div class="card shadow-0 p-2">
                <div className="card-title">
                <h3 className="text-left mb-5">
                <i class="fas fa-shopping-cart"></i> VOTRE PANIER
              </h3>
                </div>
                <div class="row g-0">
                  <div class="col-md-4">
                    <img
                      src="images/servic/analytics.png"
                      class="img-fluid rounded-3"
                      alt="..."
                    />
                  </div>
                 
                  <div class="col-md-8">
                    <div className="card shadow-sm">
                      <div class="card-body">
                       
                        <div class="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
                          <div>
                            <h5 class="card-title mb-0">
                              <a href="#">{products.title}</a>
                            </h5>
                            {/* <p class="small mb-2 mb-sm-0">Professor at Sigma College</p> */}
                          </div>
                          {/* <span class="text-dark">4.3<i class="fas fa-star text-warning ms-1"></i></span> */}
                        </div>
                        <p class="mb-3">
                        {products.description}
                        </p>
                        <div class=" justify-content-sm-between align-items-center">
                          <label htmlFor="" className="font-weight-bold">
                            <b>Tranche par mots</b>
                          </label>
                          <select
                            name=""
                            id=""
                            className="form-select js-choice z-index-9 border-0 bg-light choices__input"
                          >
                            <option value="">0-2500</option>
                            <option value="">2501-5000</option>
                            <option value="">5001-7500</option>
                            <option value="">7501-10000</option>
                            <option value="">10001-12500</option>
                            <option value="">12501-15000</option>
                          </select>
                        </div>
                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="card shadow-0 border-0 p-0 fixed-content">
                <div className="cart-title card-title-border">
                  <h5 className="text-left text-purple bg-light p-2">
                    RESUME COMMANDE
                  </h5>
                </div>
                <div className="card-body">
                  <div className="detail-title">
                    <p className="text-centerX mb-4">
                      <i class="fas fa-check-circle text-orange"></i>{" "}
                      <b>
                        Correction de manuscrit &nbsp;&nbsp; 2501 - 5000 mots
                      </b>
                    </p>
                  </div>
                  <div className="detail-tarif d-flex justify-content-between mb-4">
                    <p className="text-price">Total TTC</p>
                    <p className="number-price">50 000 FCFA</p>
                  </div>
                  <div className="btn-valid d-flex justify-content-start">
                    {/* <button className="btn btn-purple btn-block"></button> */}
                    <Link
                      to={"/payement-de-service.html"}
                      class="btn btn-purple-soft btn-block "
                    >
                      Passer la commande <i class="fas fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OtherServices />
    </Fragment>
  );
}

export default OrderCheckoutMain;
