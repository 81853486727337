import React, { Fragment, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ReadMore from "../../ReadMore";
function AboutChapter2(props) {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  });
  return (
    <Fragment>
      <section class="experience pt-100 pb-100 " id="experience">
        {/* Section*/}
        <div class="section-title mb-5">
                <p className="mb-3 text-purple text-center text-underline chap-title chap2">
                   Chapitre 2
                </p>
                <h3 className="text-center mb-4">Une Mission</h3>
                <p className=" mb-4 text-center">
                  Notre but ultime est d’offrir à notre communauté une
                  plateforme
                  <br />
                  facile d’accès et conçue pour répondre à leurs besoins. En ce
                  sens, nous avons à coeur :
                </p>
              </div>
        <div className="container d-md-none d-sm-block">
          <div className="row">
            <div className="col-md-12 mx-auto text-center">
              
              {/*  */}
              <div
                class="timeline_content text-left shadow-sm mb-3 p-2"
                data-aos="fade-right"
              >
                {/* <span>2008</span> */}
                <h4>
                  D’accompagner et de valoriser les écrivains débutants ou
                  confirmés.
                </h4>
                <ReadMore>
                  De nombreux auteurs talentueux demeurent encore dans l’ombre
                  sans trouver de solution pour mettre en avant leur travail.
                  Ainsi, à travers notre plateforme d’auto-édition, nous avons
                  l’ambition de pallier les manques liés au monde de l’édition
                  en Afrique. Notre objectif est de permettre aux jeunes auteurs
                  de s’exprimer et de partager leur talent au plus grand nombre,
                  et ce même au-delà de leur pays ; d’être accompagnés et de
                  gagner en visibilité auprès d’une cible adéquate. Pour les
                  aider à y parvenir, nous mettons à leur disposition tous les
                  outils nécessaires à l’édition, à la publication et à la
                  distribution de leurs oeuvres numériques.
                </ReadMore>
              </div>
              {/*  */}
              {/*  */}
              <div
                class="timeline_content shadow-sm p-2 mb-3"
                data-aos="fade-left"
              >
                <h4>
                  {" "}
                  D’assurer la distribution des oeuvres numériques en toute
                  transparence.
                </h4>
                <ReadMore>
                  En pleine extension, facilement accessible et à coût réduit
                  pour le lecteur, le livre numérique constitue une excellente
                  opportunité pour l’auteur. De ce fait, notre but est d’assurer
                  la distribution de votre oeuvre en toute sécurité, de le
                  rendre accessible à tous par le biais de notre librairie en
                  ligne. Notre application de lecture et d’écoute d’oeuvre
                  numérique est conçue pour un usage confortable et adapté au
                  besoin du lecteur. Que vous soyez un auteur confirmé ou
                  indépendant, Read-ing a pour volonté de mettre en relation
                  chaque auteur avec une infinité de lecteurs qui n’attendent
                  que lui.
                </ReadMore>
              </div>
              {/*  */}
              {/*  */}
              <div class="timeline_content shadow-sm p-2" data-aos="fade-right">
                <h4>De démocratiser l’E-learning.</h4>
                <ReadMore>
                  À l’aide du numérique, il est désormais possible d’apprendre
                  différemment. Vous pouvez lire, écouter des oeuvres audio
                  ainsi que vous formez où que vous soyez ! Reda-ing contribue à
                  cette nouvelle technique d’apprentissage en démocratisant
                  l’accès à la formation et à la documentation en ligne en
                  Afrique. Notre plateforme nous permet de mettre un support
                  efficace à la disposition de nos partenaires. En tant
                  qu’établissement, bénéficiez d’avantages exceptionnels en
                  offrant à vos étudiants la possibilité de suivre des cours à
                  distance directement à travers leurs mobiles ou tablettes. Ils
                  auront également un accès gratuit à une panoplie de manuels,
                  de guides éducatifs riches dans la bibliothèque universitaire
                  numérique Read-ing.
                </ReadMore>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
        {/* Section desktop */}
        <div class="container d-none d-sm-block">
          <div class="row">
            <div class="col-xl-12">
              <ul class="timeline-list">
                <li>
                  <div
                    class="timeline_content shadow-sm "
                    data-aos="fade-right"
                  >
                    {/* <span>2008</span> */}
                    <h4>
                      D’accompagner et de valoriser les écrivains débutants ou
                      confirmés.
                    </h4>
                    <ReadMore>
                      De nombreux auteurs talentueux demeurent encore dans
                      l’ombre sans trouver de solution pour mettre en avant leur
                      travail. Ainsi, à travers notre plateforme d’auto-édition,
                      nous avons l’ambition de pallier les manques liés au monde
                      de l’édition en Afrique. Notre objectif est de permettre
                      aux jeunes auteurs de s’exprimer et de partager leur
                      talent au plus grand nombre, et ce même au-delà de leur
                      pays ; d’être accompagnés et de gagner en visibilité
                      auprès d’une cible adéquate. Pour les aider à y parvenir,
                      nous mettons à leur disposition tous les outils
                      nécessaires à l’édition, à la publication et à la
                      distribution de leurs oeuvres numériques.
                    </ReadMore>
                  </div>
                </li>
                <li>
                  <div class="timeline_content shadow-sm" data-aos="fade-left">
                    <h4>
                      {" "}
                      D’assurer la distribution des oeuvres numériques en toute
                      transparence.
                    </h4>
                    <ReadMore>
                      En pleine extension, facilement accessible et à coût
                      réduit pour le lecteur, le livre numérique constitue une
                      excellente opportunité pour l’auteur. De ce fait, notre
                      but est d’assurer la distribution de votre oeuvre en toute
                      sécurité, de le rendre accessible à tous par le biais de
                      notre librairie en ligne. Notre application de lecture et
                      d’écoute d’oeuvre numérique est conçue pour un usage
                      confortable et adapté au besoin du lecteur. Que vous soyez
                      un auteur confirmé ou indépendant, Read-ing a pour volonté
                      de mettre en relation chaque auteur avec une infinité de
                      lecteurs qui n’attendent que lui.
                    </ReadMore>
                  </div>
                </li>
                <li>
                  <div class="timeline_content shadow-sm" data-aos="fade-right">
                    <h4>De démocratiser l’E-learning.</h4>
                    <ReadMore>
                      À l’aide du numérique, il est désormais possible
                      d’apprendre différemment. Vous pouvez lire, écouter des
                      oeuvres audio ainsi que vous formez où que vous soyez !
                      Reda-ing contribue à cette nouvelle technique
                      d’apprentissage en démocratisant l’accès à la formation et
                      à la documentation en ligne en Afrique. Notre plateforme
                      nous permet de mettre un support efficace à la disposition
                      de nos partenaires. En tant qu’établissement, bénéficiez
                      d’avantages exceptionnels en offrant à vos étudiants la
                      possibilité de suivre des cours à distance directement à
                      travers leurs mobiles ou tablettes. Ils auront également
                      un accès gratuit à une panoplie de manuels, de guides
                      éducatifs riches dans la bibliothèque universitaire
                      numérique Read-ing.
                    </ReadMore>
                  </div>
                </li>
                {/* <li>
                  <div class="timeline_content">
                    <span>2015-2018</span>
                    <h4>Project Manager</h4>
                    <p>
                      We gather your business and products information. We then
                      determine the direction of the project and understand your
                      goals and we combine your ideas with ours for an amazing
                      website.
                    </p>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default AboutChapter2;
