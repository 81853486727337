import lottie from 'lottie-web';
import React, { useRef, useEffect } from "react";
export default function ContactImg() {
    const container = useRef(null)
    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('./ContactJson.json')
        })
    }, [])
    return (

        <div className="container" ref={container}></div>
    );
}
