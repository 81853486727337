import Home from "./pages/Home";
import { DataStore } from "aws-amplify";
function App() {
  const clear = async () => {
    await DataStore.clear();
    console.info("Clear - DataStore");
  };
  const start = async () => {
    clear();
    await DataStore.start();
    console.info("Start - DataStore");
  };
  return (
    <>
      <Home />
    </>
  );
}

export default App;
