import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
// import {Amplify} from 'aws-amplify';
// import aws_exports from "./aws-exports";
// Amplify.configure(aws_exports);

import Amplify, { AuthModeStrategyType } from "aws-amplify";
import awsExports from "./aws-exports";

Amplify.configure({
  ...awsExports,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  },
});

ReactDOM.render(
  <BrowserRouter>
  <ScrollToTop />
  <React.StrictMode>
    <App />
  </React.StrictMode>
</BrowserRouter>,
document.getElementById("root")
);
