import React, { Fragment } from 'react';
import HeaderLeagal from './HeaderLeagal';
import MainLeagal from './MainLeagal';

function Leagal(props) {
    return (
        <Fragment>
            <HeaderLeagal/>
            <MainLeagal/>
            
        </Fragment>
    );
}

export default Leagal;