import React, { Fragment } from "react";
import Router from "../router";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
// import 'bootstrap/dist/css/bootstrap.min.css';

function Home() {
  return (
    <Fragment>
      <Header />
      <Router/>
      <Footer />
    </Fragment>
  );
}

export default Home;
