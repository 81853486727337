import React, { Fragment } from "react";
import Navbar from "./Navbar";
import "./header.scss";

function Header(props) {
  return (
    <Fragment>
      <header class="navbar-light navbar-sticky header-static"> </header>
      <Navbar/>
    </Fragment>
  );
}

export default Header;
