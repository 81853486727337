import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function TarifHeader(props) {
  return (
    <Fragment>
      <section class="bg-purple bg-aboutus align-items-center d-flex">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <h1 class="text-white">Nos tarifs</h1>
              <div class="d-flex justify-content-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-dark breadcrumb-dots mb-0">
                    <li class="breadcrumb-item">
                      <Link to="/">Accueil</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Tarifs
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default TarifHeader;
