import React, { Fragment, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
function ElearningServices(props) {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  });
  return (
    <Fragment>
      <section className="py-0">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-5" >
              <img
                src="online_learning.svg"
                className="img-fluid img-elearling-border"
                alt=""
              />
            </div>
            <div className="col-md-7">
              <h3 className="title ">
                Lancez-vous dans l’aventure de l’E-learning
              </h3>
              <p>
                Cher établissement, vous souhaitez offrir un apprentissage
                exceptionnel à vos étudiants ? Devenez partenaire Read-ing!
                Optez pour notre solution clef en main qui vous offre le
                matériel, le soutien technique et la meilleure plateforme adapté
                à l’apprentissage en ligne. Permettez à vos étudiants d’assister
                à des <strong>cours en live</strong> à travers une simple application mobile et
                d’embrasser ainsi une toute nouvelle façon de <strong>se former</strong>, d’<strong>apprendre</strong> et d’<strong>acquérir des compétences</strong>.
              </p>

              <p className="link-more">
                <Link to={'/contactez-nous.html'} class=" mb-0 btn btn-purple-soft">
                Devenez partenaire
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default ElearningServices;
