import React from "react";
import {Fragment} from "react";

function MainCgv() {
    return (
        <Fragment>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className="text-center mb-5 text-decoration-underline">CONDITIONS GÉNÉRALES DE VENTE
                                CLIENTS</h5>
                            <p className="article">ARTICLE 1. Objet</p>
                            <p> Les présentes conditions (ci-après « Conditions Générales de Vente ») ont pour objet la définition
                                des conditions de vente et de livraison des produits (ci-après « les Produits ») proposés à la vente
                                sur l’application Read-ing (ci-après « l’Appli »), par la société READ – ING SARL (ci-après
                                « Reading »), sise Abidjan – Cocody, Riviera Abatta, 05 BP 3097 Abidjan 05, société au capital de
                                1 000 000 F CFA immatriculée au Registre du Commerce et du Crédit Mobilier d’Abidjan, No :
                                CI-ABJ-03-2021-B13-08312.</p>
                            <p> La passation d’une commande par le Client sur l’application Read-ing, implique l’acceptation sans
                                réserve des Conditions Générales de Vente</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="article">ARTICLE 2. Produits et disponibilité</p>
                            <p> Les offres de Produits et de prix sont valables tant qu’elles sont visibles sur
                                l’application Read-ing,
                                et dans les conditions de disponibilité fournies au moment de la passation de commande.
                                Dans le cas d’un problème au niveau de processus de recharge du compte (cauri), Reading
                                s’engage
                                à vous fournir dans un délai de 72h, l’intégralité des cauri achetés.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="article">ARTICLE 3. Commandes</p>
                            <div className="sous-article">3.1 CRÉATION D’UN COMPTE CLIENT</div>
                            <ul>
                                <li>
                                    Vous pouvez librement passer commande sur l’application Read-ing, sous réserve
                                    :
                                </li>
                                <ul>
                                    <li>D’être une personne morale, ou une personne physique âgée d’au moins 18
                                        ans et avoir la capacité juridique, ou être titulaire d’une autorisation
                                        parentale,
                                        pour pouvoir effectuer une commande sur l’application Read-ing.
                                    </li>
                                    <li>De la création préalable et gratuite d’un Compte Client sur l’application
                                        Reading.
                                    </li>
                                    <li>De l’acceptation des Conditions Générales de vente.</li>
                                </ul>
                            </ul>
                            <p>Au moment de la création de votre Compte Client, vous vous engagez à fournir des
                                informations exactes et sincères sur votre situation, ainsi qu’à procéder avant
                                chaque nouvelle commande à une vérification des données vous concernant, et de procéder
                                sur l’application Read-ing votre Compte Client aux modifications nécessaires.
                                Reading attire en particulier votre attention sur la nécessité de fournir une adresse de
                                courrier électronique valide.<br/>
                                Reading conserve la faculté de refuser, ou d’invalider a posteriori l’inscription
                                d’un utilisateur dont les informations se révéleraient être inexactes.<br/>
                                La création d’un Compte Client implique que vous choisissiez un identifiant (une
                                adresse
                                de courrier électronique valide) et un mot de passe confidentiel, vous permettant
                                d’accéder
                                à votre Compte Client et de passer des commandes sur l’application Read-ing.<br/>
                                Ces éléments d’identification sont modifiables sur l’application Read-ing dans la
                                rubrique
                                Mon compte > Mon profil.
                            </p>
                            <p>
                                Ils sont par ailleurs confidentiels et strictement personnels, vous vous engagez en
                                conséquence à ne pas les communiquer à des tiers.<br/>
                                Tout accès à votre Compte Client au moyen de vos éléments d’identification est réputé
                                avoir été effectué par vous-même.<br/>
                                Reading ne saurait, en tout état de cause, être tenue responsable d’une utilisation
                                frauduleuse ou anormale de vos éléments d’identification, Reading se réservant le droit
                                de
                                suspendre temporairement l’accès à votre Compte Client dans le cas où de telles
                                utilisations
                                seraient constatées.<br/>
                                Reading se réserve par ailleurs l’usage de ces éléments d’identification, dans le cadre
                                exclusif de la maintenance technique de l’application.<br/>
                                Reading garantit dans ces conditions le maintien permanent de la confidentialité des
                                codes
                                d’accès.</p>
                            <div className="sous-article">3.2 PROCESSUS DE COMMANDE</div>
                            <p>Le client procède au rechargement de son compte à travers les mobil money. Il obtient un
                                crédit
                                en « cauri », qu’il utilisera pour l’achat des œuvres disponibles sur la plateforme.
                                Après avoir acheté
                                un ouvrage, l’utilisateur garde une trace de l’historique d’achat sur son profil
                                client.</p>
                            <div className="sous-article">3.3 PREUVE DE LA COMMANDE</div>
                            <p>Les registres informatisés, conservés dans les systèmes informatiques de l’application
                                Read-ing ou
                                de ses sous-traitants, dans des conditions raisonnables de sécurité seront considérés
                                comme les
                                preuves de communication, des commandes et des paiements intervenus entre les Parties.
                                L’archivage des Commandes et de l’historique est effectué sur un support fiable et
                                durable pouvant être produit à titre de preuve.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="article">ARTICLE 4. Conditions financières</p>
                            <>
                                <div className="sous-article">4.1 PRIX</div>
                                <p>Les prix des Produits sont affichés sur l’application Read-ing en Cauri toutes taxes
                                    comprises.<br/>
                                    Un (1) cauri = 100f CFA<br/>
                                    Reading se réserve le droit de modifier ses prix à tout moment, mais les Produits
                                    seront facturés sur la base des tarifs en vigueur au moment de l’enregistrement des commandes.<br/>
                                    Tant que l’utilisateur a un solde cauri positif, il pourra acheter en un clic les œuvres disponibles.</p>
                                <div className="sous-article">4.2 PAIEMENT</div>
                                <p>Les commandes sont payables exclusivement en cauri.
                                    <li>Le paiement des commandes s'effectue sur l’application Read-ing ;</li>
                                    <li>Paiement via les solutions Mobile money et PayPal.</li>
                                    <p>
                                        Les opérateurs sont seuls responsables des transactions de paiement et ont seuls pouvoir de
                                        procéder à la validation du paiement. Il revient aux opérateurs de notifier au client toutes les
                                        informations relatives à la transaction financière. Reading n’étant que le
                                        bénéficiaire des paiements, n’a aucun pouvoir pour intervenir dans l’opération financière.
                                        L’utilisateur conserve l’usage de ses cauris durant 24 mois.
                                    </p>
                                </p>
                                <div className="sous-article">4.3 HISTORIQUE DE L’ACHAT</div>
                                <p>Vous pourrez retrouver l’intégralité de vos achats depuis votre Compte Client.</p>
                            </>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="article">ARTICLE 5. Rétractation</p>
                            <p>
                                Conformément aux dispositions en vigueur, en raison de la nature de la livraison des Produits
                                numériques par téléchargement, le Client ne peut exercer son droit de rétractation
                                concernant l’achat de ces Produits.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="article">ARTICLE 6. Garanties et réclamations</p>
                            <p>
                                Tous les articles sont soumis à une garantie légale de conformité et une garantie contre les défauts
                                cachés lui permettant de renvoyer les articles achetés défectueux en application des dispositions de
                                l’article 260 de l’Acte Uniforme de l’OHADA relatif au Droit Commercial Général.</p>
                            <p>
                                Reading s’engage à vous échanger les Produits ne correspondant pas aux caractéristiques précisées
                                sur l’application, altérés ou présentant un vice apparent.</p>
                            <p>
                                Vous êtes prié(e) dans ce cas de notifier à Reading à l’adresse contact@read-ing.com toutes
                                réserves sur le Produit livré (par exemple : Produit(s) ne correspondant pas à la commande) dans
                                les cinq (5) jours ouvrés suivant la réception.</p>
                            <p>
                                Dans ce cas, toute réclamation formulée hors de ce délai ne pourra être acceptée, sauf
                                dans le cadre
                                de la garantie contre les vices cachés, la réclamation devant dans ce cas précis être
                                formulée dans
                                les plus brefs délais suivant la découverte dudit vice par le Client.</p>
                            <p>
                                En cas de vice caché, Reading s’engage à procéder à l’échange des Produits altérés.</p>
                            <p>
                                Reading vous adressera ensuite un courrier de confirmation de son acceptation du retour
                                du ou des Produits.</p>
                            <p>
                                Toute reprise acceptée par Reading, dans le cas d’un vice apparent ou de non-conformité des
                                Produits livrés, vous permettra d’obtenir le remplacement gratuit, conformément aux dispositions
                                de l’article 7 des présentes Conditions Générales de Vente, à l’exclusion de toute autre
                                indemnité et intérêts.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="article">ARTICLE 7. Remboursement</p>
                            <p>Reading ne fait pas de remboursement. Reading s’engage plutôt à échanger les produits défectueux.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="article">ARTICLE 8. Responsabilité</p>
                            <>
                                <div className="sous-article">8.1 RESPONSABILITÉ DE READING</div>
                                <ul>
                                    <li>Chacune des fiches descriptives des produits en vente sur l’application Read-ing
                                        établit une description détaillée du Produit concerné.<br/>
                                        Ces informations ont pour origine exclusive les données communiquées par les auteurs.
                                        Reading s’efforce d'offrir aux Utilisateurs de l’application Read-ing l'information la plus
                                        fiable et qualitative qui soit concernant les produits numériques en vente, mais ne garantit
                                        pas cependant l'exhaustivité de ces informations.
                                    </li>
                                    <li>Reading ne saurait être tenue pour responsable des dommages directs ou indirects,
                                        subis
                                        par les utilisateurs, clients ou par des tiers qui trouveraient leur source dans ces
                                        informations.
                                    </li>
                                    <li>Les Produits sont réputés conformes à la législation communautaire en vigueur et aux
                                        normes applicables.<br/>
                                        Si vous estimez que le Produit porte atteinte à vos droits, vous devrez promptement
                                        adresser une notification en ce sens à Reading au moyen d’un email adressé
                                        à contact@read-ing.com et précisant votre identité, vos coordonnées, l’identification du
                                        contenu illicite et des droits auxquels il a été porté atteinte.<br/>
                                        La responsabilité de Reading ne saurait être engagée en cas de non-respect de la
                                        législation du pays où le Produit est livré.
                                    </li>
                                    <li>Reading ne saurait être tenu pour responsable de l'inexécution du contrat, en cas de
                                        force
                                        majeure ou d’un cas fortuit, de perturbation ou grève totale ou partielle notamment des
                                        moyens de communications, du fait de l’autre partie ou d’un tiers ou de causes
                                        extérieures
                                        telles que les conflits sociaux, la perturbation ou la grève totale ou partielle
                                        notamment des
                                        moyens de communications, l’intervention des autorités civiles ou militaires, les
                                        catastrophes naturelles, les incendies, les dégâts des eaux, le mauvais fonctionnement
                                        ou
                                        l’interruption du réseau des télécommunications ou du réseau électrique.
                                    </li>
                                </ul>
                                <div className="sous-article">8.2 RESPONSABILITÉ DU CLIENT</div>
                                <p>Vous êtes seul(e) responsable du choix des Produits et de leur utilisation.</p>
                            </>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="article">ARTICLE 9. Téléchargement de produits numériques</p>
                            <p>Reading propose sur l’application, le téléchargement de livres numériques.</p>
                            <div className="sous-article">9.1 DÉROULEMENT DE LA PROCÉDURE DE TÉLÉCHARGEMENT</div>
                            <p>
                            L’application donne la possibilité de télécharger des Produits sous forme numérique, sous le ou
                            les formats indiqués pour chaque Produit uniquement dans l’application. Ce qui empêche l’utilisateur
                            d’éventuelles partages du fichier.</p>
                            <p>
                            Les Produits seront livrés automatiquement par voie de téléchargement sur l’application Reading,
                            dans la page Mon compte client, la rubrique « Mes Livres », sous le ou les formats indiqués pour
                            chaque Produit.</p>
                            <p>
                            Le Client doit nécessairement disposer d’un smartphone compatible, sur lequel doit être installé
                            l’application Reading permettant de lire ou écouter les produits.</p>
                            <p>
                            Une connexion à internet est recommandée.</p>
                            <p>
                            En cas d'impossibilité de télécharger les Produits, le Client devra signaler cette difficulté de
                            livraison à Reading par courrier électronique à l’adresse contact@read-ing.com dans un délai de
                            cinq jours ouvrés à compter de la Commande.</p>
                            <p>
                            Reading se chargera de régler ce problème technique sur l’application Reading.</p>
                            <p>
                            La livraison sera réputée effectuée par l'une ou l'autre méthode ci-dessus évoquée.</p>
                            <p>
                            La livraison n'interviendra qu'à réception et encaissement du paiement par Reading.</p>
                            <p>
                            Si pour une raison quelconque le Client ne peut ouvrir, lire ou écouter le fichier correspondant
                            au Produit, il peut contacter le Service Client aux coordonnées figurant sous l’article 10 des
                            présentes dans un délai de cinq jours ouvrés à compter de la Commande.
                            </p>
                            <div className="sous-article">9.2 PROPRIÉTÉ INTELLECTUELLE</div>
                            <p>
                                Les Produits téléchargés par le Client sont des fichiers numériques protégés par des dispositions
                                nationales et internationales en matière de droits d’auteur et de droits voisins. Il revient à chaque
                                client ou utilisateur d’accomplir, sous sa seule responsabilité, les formalités visant à la protection
                                de son application et de ses données au BURIDA et aux NTIC.</p>
                            <p>
                                Le Client peut uniquement consulter les Produits pour son usage exclusif.</p>
                            <p>
                                Le Client s'engage dans ces conditions à ne pas reproduire, résumer, modifier, altérer ou rediffuser
                                les Produits, sans autorisation expresse préalable de l'éditeur / de l’auteur, ce qui exclut toutes
                                opérations de transfert, de revente, de location, d’échange, et de mise à disposition des tiers par
                                tous moyens.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="article">ARTICLE 10. Service client</p>
                            <p> Pour toute information ou question, le Service Client est à votre disposition aux coordonnées
                                suivantes : Reading</p>
                            <p>Abidjan – Cocody, Riviera Abatta, 05 BP 3097 Abidjan 05</p>
                            <p><a href="mailto:contact@read-ing.com">contact@read-ing.com</a></p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="article">ARTICLE 11. Données personnelles</p>
                            <p>
                                Vos données personnelles sont traitées conformément à notre Charte Vie Privée, laquelle fait
                                partie
                                intégrante des présentes CGV.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="article">ARTICLE 12. Divers</p>
                            <>
                                <div className="sous-article">12.1</div>
                                <p>Les Conditions Générales de Vente expriment l'intégralité de vos obligations ainsi que de
                                    celles de
                                    Reading.<br/>
                                    Reading se réserve le droit de modifier unilatéralement les termes des présentes, les
                                    conditions
                                    applicables étant celles en vigueur sur l’application que vous devrez accepter au moment de
                                    passation de votre commande. Il vous est donc conseillé de vous les lire attentivement.
                                </p>
                                <div className="sous-article">12.2</div>
                                <p>Dans l’hypothèse où l’une des dispositions des Conditions Générales de Vente serait
                                    considérée
                                    nulle en vertu d’une disposition légale ou réglementaire, présente ou future, ou d’une
                                    décision de
                                    justice revêtue de l’autorité de la chose jugée et émanant d’une juridiction ou d’un
                                    organisme
                                    compétent, cette disposition du contrat serait alors réputée non écrite, toutes les autres
                                    dispositions
                                    des Conditions Générales de Vente conservant force obligatoire entre les Parties.
                                </p>
                                <div className="sous-article">12.3</div>
                                <p>Le fait pour l’une des parties de ne pas se prévaloir d’un manquement par l’autre partie à
                                    l’une
                                    quelconque des obligations visées dans le cadre des Conditions Générales de Ventes ne
                                    saurait être
                                    interprété pour l’avenir comme une revendication à l’obligation en cause
                                </p>
                            </>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="article">ARTICLE 13. Suspension – Résiliation</p>
                            <p className="sous-article">13.1 – TORTS EXCLUSIFS DE L’AUTEUR</p>
                            <p>Les Conditions Générales de Vente sont soumises au droit ivoirien.
                                En cas de différend découlant de l’exécution ou de l’interprétation des dispositions des Conditions
                                Générales de Vente, les parties s’engagent à se rapprocher et à tenter de trouver une solution
                                amiable à leur différend.
                                En cas d’échec, seuls les tribunaux ivoiriens seront compétents pour juger de ce litige.</p>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>);
}

export default MainCgv;
