import React, { Fragment, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
function AuthorServices(props) {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  });
  return (
    <Fragment>
      <section className=" py-0">
        <div className="container">
          <div className="row row-author mt-5">
            <div className="col-md-5 col5-authorx ">
              <img
                src="author_services.svg"
                className="img-fluid img-author-border"
                alt=""
              />
            </div>
            <div className="col-md-7 col7-author">
              <h3 className="title">
                Autoéditez votre oeuvre en toute tranquillité
              </h3>
              <p>
                Votre plume est indiscutablement magnifique ? Autopubliez votre
                livre, restez maitre de votre oeuvre et touchez votre lectorat à
                l’aide de Read-ing. Notre équipe de professionnels est là pour
                vous offrir un accompagnement adapté en mettant à votre
                disposition les services nécessaires à{" "}
                <strong>l’édition</strong>, à la
                <strong> publication</strong> et à la{" "}
                <strong>distribution</strong> de votre oeuvre numérique. Alors,
                chers auteurs, rejoignez la communauté Read-ing et publiez votre
                livre sur notre plateforme d’auto-édition en toute sécurité et
                transparence.
              </p>

              <p className="link-more">
                <Link
                  to={"nos-services.html"}
                  class=" btn btn-purple-soft mb-0"
                >
                  Découvrez nos services
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default AuthorServices;
