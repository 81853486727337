import React, { Fragment } from "react";

function FaqMain(props) {
  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto text-center position-relative">
              {/* <h1 className="display-6">Besoin d'aide ?</h1> */}
              <img src="images/questions.svg" alt="" className="h-50" />
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  class="accordion accordion-icon accordion-bg-light"
                  id="accordionExample2"
                >
                  <div class="accordion-item mb-3">
                    <h6 class="accordion-header font-base" id="heading-1">
                      <button
                        class="accordion-button fw-bold rounded d-inline-block collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse-1"
                        aria-expanded="true"
                        aria-controls="collapse-1"
                      >
                        Comment s'inscrire sur la plateforme ?
                      </button>
                    </h6>
                    <div
                      id="collapse-1"
                      class="accordion-collapse collapse show"
                      aria-labelledby="heading-1"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body mt-3">
                      <p></p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item mb-3">
                    <h6 class="accordion-header font-base" id="heading-2">
                      <button
                        class="accordion-button fw-bold rounded d-inline-block collapsed d-block pe-5"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse-2"
                        aria-expanded="false"
                        aria-controls="collapse-2"
                      >
                        Comment se connecter sur la plateforme ?
                      </button>
                    </h6>
                    <div
                      id="collapse-2"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading-2"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body mt-3">
                   <p></p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item mb-3">
                    <h6 class="accordion-header font-base" id="heading-3">
                      <button
                        class="accordion-button fw-bold collapsed rounded d-block pe-5"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse-3"
                        aria-expanded="false"
                        aria-controls="collapse-3"
                      >
                        Comment completer son profil auteur
                      </button>
                    </h6>
                    <div
                      id="collapse-3"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading-3"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body mt-3">
                 <p></p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item mb-3">
                    <h6 class="accordion-header font-base" id="heading-4">
                      <button
                        class="accordion-button fw-bold collapsed rounded d-block pe-5"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse-4"
                        aria-expanded="false"
                        aria-controls="collapse-4"
                      >
                        Comment publier une oeuvre
                      </button>
                    </h6>
                    <div
                      id="collapse-4"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading-4"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body mt-3">
                        <p>
                          <p></p>
                        </p>
                        <p class="mb-0">
                          <p></p>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item mb-3">
                    <h6 class="accordion-header font-base" id="heading-5">
                      <button
                        class="accordion-button fw-bold collapsed rounded d-block pe-5"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse-5"
                        aria-expanded="false"
                        aria-controls="collapse-5"
                      >
                        Additional Options and Services
                      </button>
                    </h6>
                    <div
                      id="collapse-5"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading-5"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body mt-3">
                        <p></p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item  mb-3">
                    <h6 class="accordion-header font-base" id="heading-6">
                      <button
                        class="accordion-button fw-bold collapsed rounded d-block pe-5"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse-6"
                        aria-expanded="false"
                        aria-controls="collapse-6"
                      >
                        comment fixer le prix de vente de mon livre?
                      </button>
                    </h6>
                    <div
                      id="collapse-6"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading-6"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body mt-3">
                        <p></p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item mb-3">
                    <h6 class="accordion-header font-base" id="heading-7">
                      <button
                        class="accordion-button fw-bold rounded d-inline-block collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse-7"
                        aria-expanded="true"
                        aria-controls="collapse-7"
                      >
                        comment réussir la mise en page de mon livre ?
                      </button>
                    </h6>
                    <div
                      id="collapse-7"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading-7"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body mt-3">
                      <p></p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item mb-3">
                    <h6 class="accordion-header font-base" id="heading-8">
                      <button
                        class="accordion-button fw-bold rounded d-inline-block collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse-8"
                        aria-expanded="true"
                        aria-controls="collapse-8"
                      >
                      Faire la promouvoir de son livre.
                      </button>
                    </h6>
                    <div
                      id="collapse-8"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading-8"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body mt-3">
                      <p></p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item mb-3">
                    <h6 class="accordion-header font-base" id="heading-9">
                      <button
                        class="accordion-button fw-bold rounded d-inline-block collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse-9"
                        aria-expanded="true"
                        aria-controls="collapse-9"
                      >
                       comment publier un livre audio ?
                      </button>
                    </h6>
                    <div
                      id="collapse-9"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading-9"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body mt-3">
                      <p></p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item mb-3">
                    <h6 class="accordion-header font-base" id="heading-10">
                      <button
                        class="accordion-button fw-bold rounded d-inline-block collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse-10"
                        aria-expanded="true"
                        aria-controls="collapse-10"
                      >
                       Devenir une Étoile Read-ing.
                      </button>
                    </h6>
                    <div
                      id="collapse-10"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading-10"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body mt-3">
                      <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default FaqMain;
