import React, { useEffect, useState, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

function Checkout() {
  var data = JSON.stringify({
    apikey: "1773118837628ad28155f6b0.86985352", //   YOUR APIKEY
    site_id: "297297", //YOUR_SITE_ID
    mode: "PRODUCTION",
    transaction_id: Math.floor(Math.random() * 100000000).toString(), //
    amount: 100,
    currency: "XOF",
    alternative_currency: "",
    description: " TEST INTEGRATION ",
    customer_id: "172",
    customer_name: "KOUADIO",
    customer_surname: "Francisse",
    customer_email: "harrissylver@gmail.com",
    customer_phone_number: "+225004315545",
    customer_address: "Antananarivo",
    customer_city: "Antananarivo",
    customer_country: "CM",
    customer_state: "CM",
    customer_zip_code: "065100",
    notify_url: "https://webhook.site/d1dbbb89-52c7-49af-a689-b3c412df820d",
    return_url: "https://webhook.site/d1dbbb89-52c7-49af-a689-b3c412df820d",
    channels: "ALL",
    metadata: "user1",
    lang: "FR",
    invoice_data: {
      Donnee1: "",
      Donnee2: "",
      Donnee3: "",
    },
  });
  var config = {
    method: "post",
    url: "https://api-checkout.cinetpay.com/v2/payment",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  const [payment, SetPayment] = useState("");
  useEffect(() => {
    axios(config)
      .then(function (response) {
        SetPayment(response.data.data.payment_url);
      })
      .catch(function (error) {
        console.log(error);
      });
  });

  return (
    <Fragment>
      <a href={payment} target={"_blank"}>
        payer
      </a>
    </Fragment>
  );
}

export default Checkout;
