import React, { Fragment } from 'react';
import FaqHeader from './FaqHeader';
import FaqMain from './FaqMain';

function Faq(props) {
    return (
        <Fragment>
            <FaqHeader/>
            <FaqMain/>
        </Fragment>
    );
}

export default Faq;