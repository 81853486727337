import React, { Fragment } from 'react';
import ServicesHeader from './ServicesHeader';
import ServicesMain from './ServicesMain';
import './Services.scss'

function Services(props) {
    return (
        <Fragment>
            <ServicesHeader/>
            <ServicesMain/>
            
        </Fragment>
    );
}

export default Services;