import React from "react";
import { Fragment } from "react";
import MainCgv from "./MainCgv";
import  './cgv.css';

function Cgv() {
  return (
    <Fragment>
      <MainCgv />
    </Fragment>
  );
}

export default Cgv;
