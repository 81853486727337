import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
function NoMatch() {
  let location = useLocation();

  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card justify-content-center">
                              <img src="images/pagenotfound.svg" alt=""  className="card-img-top h-50"/>

              </div>
              <h3 className="text-center">
                No match for <code>{location.pathname}</code>
              </h3>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default NoMatch;
