import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./fastaction.scss";
function FastAction(props) {
  return (
    <Fragment>
      <div className="container fast_action mb-5">
        <div className="row mt-5">
          <div className="col-md-12 fast-action py-3">
       
            <h3 className="fast-title">
              Vous êtes un établissement ? Devenez partenaire Read-Ing.
            </h3>
            <p className="text-fast">
              Bénéficiez d’avantages exceptionnels, en permettant à vos étudiants
              de suivre les cours en live depuis leurs mobiles.
            </p>
            <div className="light-fast">
              <Link to="/contactez-nous.html" className="btn btn-purple-soft "> Contactez nous</Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FastAction;
