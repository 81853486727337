import React, { Fragment } from "react";

// Le tableau des tarifs
const prices = [
  {from:0, to:2500, amount: 10000},
  {from:2501, to:5000, amount: 18000},
  {from:5001, to:7500, amount: 27000},
  {from:7501, to:10000, amount: 35000},
  {from:10001, to:12500, amount: 42000},
  {from:12501, to:15000, amount: 49000},
  {from:15001, to:17500, amount: 56000},
  {from:17501, to:20000, amount: 63000},
  {from:20001, to:22500, amount: 70000},
  {from:22501, to:25000, amount: 76500},
]

function TarifMain(props) {
  return (
    <Fragment>
      <section className="py-5 price-wrap">
        <div className="container">
          <div className="row g-4">
            <div class="col-md-6 col-xl-4">
              <div class="card border rounded-3 p-2 p-sm-4 h-100">
                <div class="card-header p-0">
                  <div class="d-flex justify-content-between align-items-center p-3 bg-light rounded-2">
                    <div>
                      <h5 class="mb-0 title">Corrections Manuscrit </h5>
                    </div>
                  </div>
                </div>
                <div class="position-relative my-3 text-center">
                  <hr />
                  <p class="small position-absolute top-50 start-50 translate-middle bg-body px-3">
                    Tranche par mots
                  </p>
                </div>
                
                <div class="card-body pt-0">
                  <ul class="list-unstyled mt-2 mb-0">
                    {/* Ici tu déroule ton array  */}
                    {prices.map((price,i)=> <li className="mb-3 h6 fw-light">
                      <i className="fas fa-check-circle text-success me-2"></i>
                      <b>{price.from}-{price.to}</b>: {price.amount.toLocaleString()} FCFA
                    </li>)}
                  </ul>
                </div>
                <div class="card-footer text-center d-grid pb-0">
                  <button type="button" class="btn btn-purple-soft mb-0">
                    Commander
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-4">
              <div class="card border rounded-3 p-2 p-sm-4 h-100">
                <div class="card-header p-0">
                  <div class="d-flex justify-content-between align-items-center p-3 bg-light rounded-2">
                    <div>
                      <h5 class="mb-0 title">Lecture et Réécriture</h5>
                    </div>
                  </div>
                </div>
                <div class="position-relative my-3 text-center">
                  <hr />
                  <p class="small position-absolute top-50 start-50 translate-middle bg-body px-3">
                    Tranche par mots
                  </p>
                </div>

                <div class="card-body pt-0">
                  <ul class="list-unstyled mt-2 mb-0"></ul>
                </div>
                <div class="card-footer text-center d-grid pb-0">
                  <button type="button" class="btn btn-purple-soft mb-0">
                    Commander
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-4">
              <div class="card border rounded-3 p-2 p-sm-4 h-100">
                <div class="card-header p-0">
                  <div class="d-flex justify-content-between align-items-center p-3 bg-light rounded-2">
                    <div>
                      <h5 class="mb-0 title">Création de Couverture </h5>
                    </div>
                  </div>
                </div>
                <div class="position-relative my-3 text-center">
                  <hr />
                  <p class="small position-absolute top-50 start-50 translate-middle bg-body px-3">
                     Basic
                  </p>
                </div>

                <div class="card-body pt-0">
                  <ul class="list-unstyled mt-0 mb-2">
                    <li class="mb-3 h6 fw-light">
                      <i class="fas fa-check-circle text-success me-2"></i>
                      <b>10 000 FCFA</b>
                      <br />
                      <p>
                        soumettez votre image ou nous vous proposons 2 images au
                        choix. 1 retouche de la commande après livraison
                      </p>
                    </li>
                  </ul>
                  <div class="position-relative my-3 text-center">
                    <hr />
                    <p class="small position-absolute top-50 start-50 translate-middle bg-body px-3">
                       Medium
                    </p>
                  </div>
                  
                  <ul class="list-unstyled mt-2 mb-2">
                    <li class="mb-3 h6 fw-light">
                      <i class="fas fa-check-circle text-success me-2"></i>
                      <b>15 000 FCFA</b>
                      <br />
                      <p>
                        soumettez votre image ou nous vous proposons de 3 images
                        au choix , 2 retouches de la commande après livraison
                      </p>
                    </li>
                  </ul>
                  <div class="position-relative my-3 text-center">
                    <hr />
                    <p class="small position-absolute top-50 start-50 translate-middle bg-body px-3">
                       Premium
                    </p>
                  </div>
                  <ul class="list-unstyled mt-2 mb-0">
                    <li class="mb-3 h6 fw-light">
                      <i class="fas fa-check-circle text-success me-2"></i>
                      <b>25 000 FCFA</b>
                      <br />
                      <p>
                      Echange  téléphonique avec le graphiste, illustration  personnalisée. 5 propositions d'images; 3 retouches de la couverture après livraison
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="card-footer text-center d-grid pb-0">
                  <button type="button" class="btn btn-purple-soft mb-0">
                    Commander
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default TarifMain;
