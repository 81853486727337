// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const AuthorStatus = {
  "PUBLIC": "Public",
  "DRAFT": "Draft",
  "FORMER": "Former"
};

const BookStatus = {
  "DRAFT": "Draft",
  "FINALIZED": "Finalized",
  "PUBLICATION_WANTED": "PublicationWanted",
  "PUBLICATION_POSTPONED": "PublicationPostponed",
  "PUBLICATION_DECLINED": "PublicationDeclined",
  "PUBLISHED": "Published",
  "UPDATING": "Updating",
  "UPDATE_WANTED": "UpdateWanted",
  "UPDATE_DECLINED": "UpdateDeclined",
  "UNPUBLISHED": "Unpublished"
};

const BookType = {
  "AUDIO": "Audio",
  "EPUB": "Epub",
  "TEXT": "Text"
};

const PurchaseStatus = {
  "AUTHOR": "Author",
  "PENDING": "Pending",
  "VALIDATED": "Validated",
  "FAILED": "Failed",
  "NO_CREDIT": "NoCredit"
};

const { AuthorUser, Author, Draft, DraftContent, Family, Book, BookContent, Purchase, ReaderUser, Refill, BookLike, BookRating, FollowAuthor, NotifyFollower, PurchasedContent, Payment, Category, SubCategory, School, Student, StudentGroup, Course, Teacher, LiveCourse, ToTeach, SchoolSubscription, SchoolYear, SchoolUser, Product, Price, OrderLine, Order, Client, S3Object } = initSchema(schema);

export {
  AuthorUser,
  Author,
  Draft,
  DraftContent,
  Family,
  Book,
  BookContent,
  Purchase,
  ReaderUser,
  Refill,
  BookLike,
  BookRating,
  FollowAuthor,
  NotifyFollower,
  PurchasedContent,
  Payment,
  Category,
  SubCategory,
  School,
  Student,
  StudentGroup,
  Course,
  Teacher,
  LiveCourse,
  ToTeach,
  SchoolSubscription,
  SchoolYear,
  SchoolUser,
  Product,
  Price,
  OrderLine,
  Order,
  Client,
  AuthorStatus,
  BookStatus,
  BookType,
  PurchaseStatus,
  S3Object
};