import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import ScrollTop from "../scrolltop/ScrollTop";
import Copyright from "./Copyright";
import "./footer.scss";
import MenuFooter from "./MenuFooter";
import SocialMenu from "./SocialMenu";
import SubInfo from "./SubInfo";
function Footer(props) {
  return (
    <Fragment>
      <footer class="bg-purplew pt-5">
        <div class="container">
          <div class="row g-4">
            <div class="col-sm-3">
              <Link class="me-0" href="index.html">
                <img
                  class="h-50px bg-white rounded-circle shadow-lg"
                  src="images/logo/logo-reading.svg"
                  alt="logo"
                />
              </Link>
              <p class="my-3 text-muted">
                Read-ing, votre plateforme de lecture, d’écoute et
                d’apprentissage numérique.{" "}
              </p>
              <ul class="list-inline mb-0 mt-3">
                <li class="list-inline-item">
                  {" "}
                  <a class="btn btn-white btn-sm shadow px-2 text-facebook" 
                  href="https://web.facebook.com/readingappl"
                  target="_blank"
                  >
                    <i class="fab fa-fw fa-facebook-f"></i>
                  </a>

                </li>
                
           
                <li class="list-inline-item">
                  {" "}
                  <Link
                    class="btn btn-white btn-sm shadow px-2 text-twitter"
                    to={"/"}
                  >
                    <i class="fab fa-fw fa-twitter"></i>
                  </Link>{" "}
                </li>
                <li class="list-inline-item">
                  {" "}
                  <Link
                    class="btn btn-white btn-sm shadow px-2 text-linkedin"
                    to={"/"}
                  >
                    <i class="fab fa-fw fa-linkedin-in"></i>
                  </Link>{" "}
                </li>
              </ul>
            </div>

            <div class="col-sm-3">
              <h5 class="mb-2 mb-md-4 text-white">Entreprise</h5>
              <ul class="nav flex-column text-primary-hover">
                <li class="nav-item">
                  <Link class="nav-link" to={"/nos-services.html"}>
                    Nos services
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to={"/nos-engagements.html"}>
                    Nos engagements
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to={"/Qui-sommes-nous.html"}>
                    Qui sommes nous
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to={"/faq.html"}>
                    FAQ
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to={"/contactez-nous.html"}>
                    Contactez nous
                  </Link>
                </li>
              </ul>
            </div>

            <div class="col-sm-3">
              <h5 class="mb-2 mb-md-4 text-white">Liens utiles</h5>
              <ul class="nav flex-column text-primary-hover">
                <li class="nav-item">
                  <Link class="nav-link" to={"/conditions-generale-d-utilisation.html"}>
                    Conditions générales d'utilisation
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to={"/conditions-générales-de-vente.html"}>
                    Conditions générales de vente
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to={"/politique-de-confientialite.html"}>
                    Politique de confidentialité
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to={"/mentions-legal.html"}>
                    Mentions légales
                  </Link>
                </li>
              </ul>
            </div>

            <div class="col-sm-3">
              <h5 class="mb-2 mb-md-4 text-white">Contact</h5>
              <p class="mb-2 text-muted">
                Adresse:
                <span class="h6 fw-light text-white ms-2">Abidjan, Côte d'Ivoire</span>
                <span class="d-block small">Lundi–vendredi : 9h00 - 17h00</span>
              </p>

              <p class="mb-0 text-muted">
                Email:
                <span class="h6 fw-light text-white ms-2">
                contact@read-ing.com
                </span>
              </p>
              <h5 class=" mt-3 text-white text-gray">Application</h5>
              <div class="row g-2 mt-2">
                <div class="col-6 col-sm-4 col-md-3 col-lg-6">
                  
                  <Link to={"/"}>
                    {" "}
                    <img src="google-play.svg" alt="" />{" "}
                  </Link>
                </div>
                <div class="col-6 col-sm-4 col-md-3 col-lg-6">
                  <Link to={"/"}>
                    {" "}
                    <img
                      src="app-store.svg"
                      alt="app-store"
                    />{" "}
                  </Link>
                </div>
              </div>
            </div>

            <hr class="mt-4 mb-0" />
            <div class="py-3">
              <div class="container px-0">
                <div class="d-md-flex justify-content-between align-items-center py-3 text-center text-md-left">
                  <div class="text-muted text-primary-hover">
                    {" "}
                    Copyrights ©&nbsp;2022 &nbsp;{" "}
                    <Link to={"/"} class="text-reset">Read-ing</Link>&nbsp;&nbsp;Tous les droits reservés.{" "}
                
                  </div>
                  <div class=" mt-3 mt-md-0">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item text-primary-hover">
                        <div class="dropup mt-0 text-center text-sm-end">
                          <Link
                            class="dropdown-toggle nav-link"
                            to={"/"}
                            role="button"
                            id="languageSwitcher"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fas fa-globe me-2"></i>Language
                          </Link>
                          <ul
                            class="dropdown-menu min-w-auto"
                            aria-labelledby="languageSwitcher"
                          >
                            <li>
                              <Link class="dropdown-item me-4 text-body" to={"/"}>
                                <img
                                  class="fa-fw me-2"
                                  src="images/flags/uk.svg"
                                  alt=""
                                />
                                English
                              </Link>
                            </li>
                            <li>
                              <Link class="dropdown-item me-4 text-body" to={"/"}>
                                <img
                                  class="fa-fw me-2"
                                  src="images/flags/gr.svg"
                                  alt=""
                                />
                                German{" "}
                              </Link>
                            </li>
                            <li>
                              <Link class="dropdown-item me-4 text-body" to={"/"}>
                                <img
                                  class="fa-fw me-2"
                                  src="images/flags/sp.svg"
                                  alt=""
                                />
                                French
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

export default Footer;
